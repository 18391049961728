import Chart from 'chart.js';
import { COUNTRIES, COUNTRY_COLORS } from './interactive-veto-chart.data';

export function listenMouseDown(callback: (event: MouseEvent) => void) {
    function mouseMoveEvent(event: MouseEvent) {
        event.preventDefault();
        callback(event);
    }
    function stopEvents() {
        window.removeEventListener('mousemove', mouseMoveEvent);
        window.removeEventListener('mouseup', stopEvents);
        window.removeEventListener('mouseleave', stopEvents);
    }

    window.addEventListener('mousemove', mouseMoveEvent);
    window.addEventListener('mouseup', stopEvents);
    window.addEventListener('mouseleave', stopEvents);
}

export function listenTouch(callback: (event: TouchEvent) => void) {
    function touchMoveEvent(event: TouchEvent) {
        event.preventDefault();
        event.stopImmediatePropagation();
        callback(event);
    }
    function stopTouchEvents() {
        window.removeEventListener('touchmove', touchMoveEvent);
        window.removeEventListener('touchend', stopTouchEvents);
    }
    window.addEventListener('touchmove', touchMoveEvent, {passive: false});
    window.addEventListener('touchend', stopTouchEvents);
}

export function initChart(canvasId: string, totalsByCountry: number[]): Chart {
    const chart: Chart = new Chart(canvasId, {
        type: 'doughnut',
        data: {
            labels: COUNTRIES,
            datasets: [
                {
                    data: totalsByCountry,
                    backgroundColor: COUNTRY_COLORS,
                    borderWidth: 0
                }
            ]
        },
        options: {
            responsive: true,
            cutoutPercentage: 75,
            legend: {
                display: false
             }
        }
    });

    return chart;
}

export function updateChart(chart: Chart, totalsByCountry: number[]): void {
    if (chart.data.datasets && chart.data.datasets[0] && chart.data.datasets[0].data) {
        chart.data.datasets[0].data = totalsByCountry;
        chart.update();
    }
}
import React , { useRef, useEffect, useState, ReactNode, useLayoutEffect }from 'react';
import { connectState, ExhibitionStateProps } from './exhibition.state';
import { Content } from '../../content/content.component';
import { MetroLayout, MetroBlock } from '../../metro';
import {
    HeadBox,
    BodyBox
} from '../../boxes';
import {
    exhibitionOrderClassName,
    exhibitionBoxModalClassName,
    exhibitionBoxModalColsClassName,
    exhibitionBoxModalColClassName,
    exhibitionBodyClassName,
    exhibitionBoxFadeInFromBottom,
    exhibitionBoxIsVisible,
    exhibitionBoxPageStateOnLoad,
    exhibitionBoxHeight,
    exhibitionModalClassName
} from './exhibition.styles';
import { FONT_WEIGHT_LIGHT } from '../../../style/constants';
import { MetroContent } from '../../metro-content/metro-content.component';
import { zeroPad } from '../../../utils';
import { BoxData, ExhibitionData } from '../../../state/interfaces';
import { IconButton } from '../../icon-button/icon-button.component';
import { Modal } from '../../modal/modal.component';
import { BoxPage } from '../../box-pages/box-page.component';
import { Svg } from '../../svg/svg.component';
import { boxPageButtonPadClassName } from '../../box-pages/box-page.styles';
import { History} from 'history';
import { cpus } from 'os';

interface ExhibitionProps extends ExhibitionStateProps {
    match: any,
    history: any
}

interface ExhibitionState {
    selectedBoxData: BoxData | null,
    pageBodyOpen: boolean,
    transform:boolean ,
    boxAlias: string,
    modalBoxAlias?: boolean,
    firstLoading?:boolean,
    changeColorCloseIcon?:boolean
}

   
class Exhibition extends React.Component<ExhibitionProps, ExhibitionState> {
    divRef = React.createRef<HTMLDivElement>();
    ref = React.createRef<HTMLDivElement>();
    unlisten:any = null;

    public state: ExhibitionState = {
        selectedBoxData: null,
        pageBodyOpen: false,
        transform: true,
        boxAlias: this.props.match.params.alias,
        modalBoxAlias: (this.props.match.params.boxAlias)? true: false,
        firstLoading: true,
    };

    resize() {
        let currentHideNav = (window.innerWidth <= 992);
        if (currentHideNav !== this.state.changeColorCloseIcon) {
            this.setState({changeColorCloseIcon: currentHideNav});
        }
    }

    componentDidMount() {
        this.setLayoutType();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if(this.props.match.params.boxAlias){
            this.setState({firstLoading: false})
            if(this.state.modalBoxAlias){
                
                setTimeout(()=>{
                this.unlisten = this.props.history.listen((location:any, action:any) => {
                    if(this.state.modalBoxAlias){
                        this.setState({modalBoxAlias: false});
                        this.scrollTo(this.state.boxAlias);
                    }  
                });
                }, 250)
            }
           
        }else{
            const idScroll = `/exhibitions/${this.state.boxAlias }`;
            const windowPath = window.location.pathname ;
            if(idScroll == windowPath){
                
                this.setState({firstLoading: false})
            }else{
                this.setState({firstLoading: true})
            }

            setTimeout(()=>{
             this.scrollTo(this.state.boxAlias);
            }, 250)
        }

    }

    componentWillUnmount() {
        if(this.state.modalBoxAlias){
            this.unlisten();
        }
        window.removeEventListener("resize", this.resize.bind(this));
    }

    setLayoutType() {
        this.props.setAppState({
            layoutType: 'default'
        });
    }


    getExhibition(): ExhibitionData[]  {
        const { exhibitions, match } = this.props;
        if (exhibitions) {
            const refs = exhibitions.reduce((acc:any, value) => {
                acc[value.alias] = React.createRef();
                return acc;
            }, {});
            return exhibitions;
        }

        return [];
    }
   
    getCurrentExhibition(){
        const { exhibitions, match } = this.props;
        const alias = match.params.alias;
        if (!exhibitions ) {
            return null;
        }

        for (let i = 0; i < exhibitions.length; i++) {
            if (exhibitions[i].alias === alias) {
                return exhibitions[i]
            }
        }
    }

    getBoxData() {
        
        const { exhibitions, match } = this.props;
        const { alias, boxAlias } = match.params;

        if (!exhibitions || !boxAlias) {
            return null;
        }

        for (let i = 0; i < exhibitions.length; i++) {
            if (exhibitions[i].alias === alias) {
                const metroBlocks = exhibitions[i].page?.metro_layout?.metro_blocks;
                if (metroBlocks) {
                    for (const metroBlock of metroBlocks) {
                        
                        if (metroBlock.box) {
                            if (metroBlock.box.alias === boxAlias) {
                                return metroBlock.box
                            }
                        }
                        else if (metroBlock.child_blocks) {
                            for (const childBlock of metroBlock.child_blocks) {
                                if (childBlock.box && (childBlock.box.alias === boxAlias)) {
                                    return childBlock.box
                                }
                            }
                        }
                        else if (metroBlock.child_blocks_left) {
                            for (const childBlock of metroBlock.child_blocks_left) {
                                if (childBlock.box && (childBlock.box.alias === boxAlias)) {
                                    return childBlock.box
                                }
                            }
                        }
                    }
                }
                return null;
            }
        }   
            
    }

    closeModal() {
        this.props.setAppState({
            modalBox:  null,
            modalCustomContent: false
        });
        
    }    
    
    scrollTo(alias: string ) {
    
        let currentDiv:any = document.getElementById(alias) ;
        let top = currentDiv ? currentDiv.getBoundingClientRect().top + currentDiv.ownerDocument.defaultView.pageYOffset -100 :  0
        this.divRef.current!.scrollBy({
            top
        })
        
    }

    render() {
        const exhibitionArray = this.getExhibition();
        const boxAliasParam = this.props.match.params.boxAlias
        const exhibition = (exhibitionArray)?this.getCurrentExhibition():null;
        const boxData = this.getBoxData();
        
        const boolBoxAliasParam = this.state.modalBoxAlias;
        
        return (
            <div className={exhibitionBoxHeight} ref={this.divRef} >
                <Content>
                    { exhibitionArray.map((exhibition, index) => (
                        exhibition && exhibition.page &&
                        <MetroLayout key={index} >
                            
                            <MetroBlock  id={exhibition.alias} boxAlias={boolBoxAliasParam}> 
                                <HeadBox
                                    title={ exhibition.title }
                                    detail={ exhibition.page.detail }
                                    image={ exhibition.image_url }/>
                                    
                            </MetroBlock>
                            
                            <MetroBlock id={exhibition.alias} >
                                <BodyBox
                                    type={ 'title' }
                                    linkTo={ `/exhibitions/${exhibition.alias}/introduction` }
                                    onClick={ () => this.props.setAppState({modalCustomContent: true, modalBox: null}) }
                                    exhibitionMainBlock={ {
                                        order: exhibition.order,
                                        caption: exhibition.caption,
                                        description: exhibition.page.description
                                    } }/>
                            </MetroBlock>

                            {   
                                exhibition.page.metro_layout && exhibition.page.metro_layout.metro_blocks.map((metroBlock, index) => 
                                    (
                                        
                                    <MetroContent key={ index }  id={exhibition.alias} boxAlias={boolBoxAliasParam} firstLoaded={this.state.firstLoading} metroBlock={ metroBlock }  parentPath={ `/exhibitions/${exhibition.alias}` }/>
                                   
                                    )
                                )
                            }
                            
                        </MetroLayout>
                    ))}
                        <Modal open={
                            Boolean(exhibition && exhibition.page)
                            && Boolean(
                                (boxAliasParam === 'introduction') || boxData
                            )
                        }>
                            <div style={ {
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                margin: '15px',
                                zIndex: 1200} }>
                                { (boxData?.type == 'text')
                                    ?
                                    (<IconButton size={ 30 } svgName="closeWhite" linkTo={ `/exhibitions/${exhibition?.alias}` } />)
                                    :(this.state.changeColorCloseIcon && boxAliasParam != 'introduction')?(
                                        (<IconButton size={ 30 } svgName="closeWhite" linkTo={ `/exhibitions/${exhibition?.alias}` } />)
                                    ):
                                    (<IconButton size={ 30 } svgName="close" linkTo={ `/exhibitions/${exhibition?.alias}` }/>)
                                }
                                
                            </div>

                            {
                                (boxAliasParam === 'introduction') && exhibition && exhibition.page
                                ? (
                                <div style={ {height: '100%'} }>
                                    <div className={ exhibitionModalClassName }>
                                        <div className={ exhibitionOrderClassName }>
                                            { zeroPad(exhibition.order, 2) }
                                        </div>
                                        <h2>{ exhibition.caption }</h2>
                                        <div dangerouslySetInnerHTML={{__html:exhibition.page.description}} className={ exhibitionBodyClassName } style={ {fontWeight: FONT_WEIGHT_LIGHT} } />
                                    </div>

                                    <div className={ boxPageButtonPadClassName }>
                                        {
                                            exhibition.page.$previousBoxUrl
                                            ? (
                                                <IconButton linkTo={ exhibition.page.$previousBoxUrl } svgName="previous" size={ 100 }/>
                                            )
                                            : (
                                                <span style={ {opacity: .15, cursor: 'not-allowed'} }>
                                                    <Svg name="previous" size={ 100 }/>
                                                </span>
                                                
                                            )
                                        }
                                        {
                                            exhibition.page.$nextBoxUrl
                                            ? (
                                                <IconButton linkTo={ exhibition.page.$nextBoxUrl } svgName="next" size={ 100 }/>
                                            )
                                            : (
                                                <span style={ {opacity: .15, cursor: 'not-allowed', marginLeft: '20px'} }>
                                                    <Svg name="next" size={ 100 }/>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                            : (
                                
                                boxData && (
                                    <BoxPage boxData={ boxData }/>
                                )
                            )
                        }
                        </Modal> 
                </Content>
            </div>
        );
    }
}

export default connectState(Exhibition);



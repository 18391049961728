import { style, keyframes, media } from 'typestyle';
import {
    FONT_FAMILY_1,
    FONT_FAMILY_2,
    FONT_WEIGHT_LIGHT,
    FONT_WEIGHT_EXTRABOLD,
    HEADER_HEIGHT,
    COLOR_BACKGROUND,
    FONT_WEIGHT_REGULAR, MEDIA_LG, MEDIA_MD
} from '../../../style/constants';

const transitionSpeed = '300ms';

const exhibitionItemAnimationName = keyframes({
    from: {
        opacity: 0,
        transform: 'translateX(100px)'
    },
    to: {
        opacity: 1,
        transform: 'translateX(0px)'
    }
});

export const exhibitionsClassName = style({
    $debugName: 'exhibitionsClassName',

    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowY: 'hidden',
    overflowX: 'auto'
});


export const exhibitionItemColorClassName = style({
    $nest: {
        '&:nth-child(2n)': {
            background: 'linear-gradient(0deg, rgba(36,58,106,1) 0%, rgba(26,41,74,1) 100%)'
        },
        '&:nth-child(2n+1)': {
            background: 'linear-gradient(0deg, rgba(55,91,143,1) 0%, rgba(40,63,99,1) 100%)'
        }
    }
});

export const exhibitionItemInnerClassName = style(
    {
        width: '100%',
        height: 'calc(100% + 40px)',
        overflow: 'hidden',
        padding: `${HEADER_HEIGHT + 40}px 20px 45px 20px`,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        boxSizing: 'border-box',
        margin: '-20px 0'
    },
    media(MEDIA_LG, {
        padding: `${HEADER_HEIGHT + 40}px 30px 45px 30px`,
    })
);

export const exhibitionItemTitleClassName = style(
    {
        flexGrow: 1,
        flexShrink: 0,
        opacity: 0,
        pointerEvents: 'none',
        transition: transitionSpeed,
        width: '320px',
        fontFamily: FONT_FAMILY_1,
        fontSize: '42px',
        lineHeight: 1,
        fontStyle: 'italic',
        color: COLOR_BACKGROUND,
        fontWeight: FONT_WEIGHT_REGULAR,

        $nest: {
            '*': {
                fontFamily: FONT_FAMILY_1,
                fontSize: '42px',
                fontStyle: 'italic',
                color: COLOR_BACKGROUND,
                fontWeight: FONT_WEIGHT_REGULAR
            },
            '& p, & small': {
                fontSize: '28px'
            }
        }
    },
    media(MEDIA_LG, {
        width: '360px',
    })
);

export const exhibitionItemDetailsClassName = style({
    height: '100px'
});

export const exhibitionItemCountClassName = style({
    fontFamily: FONT_FAMILY_2,
    fontWeight: FONT_WEIGHT_LIGHT,
    fontSize: '22px',
    color: COLOR_BACKGROUND
});

export const exhibitionItemCaptionClassName = style(
    {
        fontFamily: FONT_FAMILY_2,
        fontWeight: FONT_WEIGHT_EXTRABOLD,
        fontSize: '22px',
        lineHeight: '24px',
        transition: transitionSpeed,
        width: '240px',
        color: COLOR_BACKGROUND
    },
    media(MEDIA_LG, {
        width: '280px',
    })
);

export const exhibitionItemYearsClassName = style({
    fontFamily: FONT_FAMILY_2,
    fontWeight: FONT_WEIGHT_EXTRABOLD,
    fontSize: '16px',
    color: COLOR_BACKGROUND
});

export const exhibitionItemActionClassName = style({
    position: 'relative',
    overflow: 'hidden',
    height: '60px',
    margin: '15px 0 -60px 0',
    opacity: 0,
    pointerEvents: 'none',
    transition: transitionSpeed,

    $nest: {
        '& > a': {
            display: 'block',
            position: 'relative',
            float: 'right',
            borderRadius: '50%',
            height: '60px',
            width: '60px',
            overflow: 'hidden',

            $nest: {
                '& > img': {
                    display: 'block'
                }
            }
        },

        '& > a > img': {
          width: '60px',
          height: '60px',
        },

        '&::before, &::after': {
            display: 'block',
            content: `''`,
            clear: 'both'
        }
    }
});

export const exhibitionItemOpenClassName = style(
    {
        width: '100%',

        $nest: {
            [`.${exhibitionItemInnerClassName}`]: {
                boxShadow: 'inset 0px 0px 10px 5px rgba(0,0,0,0.75)'
            },
            [`.${exhibitionItemTitleClassName}`]: {
                opacity: 1,
                pointerEvents: 'auto'
            },
            [`.${exhibitionItemDetailsClassName}`]: {
                // minHeight: '50px'
            },
            [`.${exhibitionItemActionClassName}`]: {
                opacity: 1,
                pointerEvents: 'auto',
                marginBottom: 0
            }
        }
    },
    media(MEDIA_MD, {
        width: '420px'
    })
);

export const exhibitionItemClassName = style(
    {
        $debugName: 'exhibitionItemClassName',

        width: '280px',
        height: '100%',
        float: 'left',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        flexShrink: 0,
        flexGrow: 0,
        transition: transitionSpeed,
        maxWidth: '420px',
        minWidth: '280px',

        opacity: 0,
        animationName: exhibitionItemAnimationName,
        animationDuration: '600ms',
        animationIterationCount: 1,
        animationDirection: 'ease-out',
        animationFillMode: 'forwards',
        $nest: {
            '&:hover': {
                width: '100%',
                $nest: {
                    [`.${exhibitionItemInnerClassName}`]: {
                        boxShadow: 'inset 0px 0px 10px 5px rgba(0,0,0,0.75)'
                    },
                    [`.${exhibitionItemTitleClassName}`]: {
                        opacity: 1,
                        pointerEvents: 'auto'
                    },
                    [`.${exhibitionItemDetailsClassName}`]: {
                        // minHeight: '50px'
                    },
                    [`.${exhibitionItemActionClassName}`]: {
                        opacity: 1,
                        pointerEvents: 'auto',
                        marginBottom: 0
                    }
                }
            }
        }
    },
    media(MEDIA_LG, {
        width: '340px',
        $nest: {
            '&:hover': {
                width: '420px',
            }
        }
    })
);

export const exhibitionScrollArrow = style(
    {
        $debugName: 'exhibitionArrow',
        width: '30px; height: 100%',
        backgroundColor: 'transparent',
        display: 'block', 
        position: 'absolute',
        bottom: 0,
        zIndex: 99,
    }
);

export const exhibitionLeft = style(
    {
        left: 0
    }
);

export const exhibitionRight = style(
    {
        right: 0,
        
    }
)

const shake = keyframes({
    '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)'
    },
      
    '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)'
    },
    
    '30%, 50%, 70%':{
        transform: 'translate3d(-4px, 0, 0)'
      },
    
    '40%, 60% ':{
        transform: 'translate3d(4px, 0, 0)'
    }
});

export const exhibitionItemSvgClassName = style(
    {
        $nest: {
            '&:hover':{
                animation: `${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
                transform: 'translate3d(0, 0, 0)',
                backfaceVisibility: 'hidden',
                perspective: '1000px'
            }
        }
        
    }
)
class MediaSet {
    private values: {
        sm: string | number,
        md?: string | number,
        lg?: string | number,
        xl?: string | number
    };

    constructor(
        sm: string | number,
        md?: string | number,
        lg?: string | number,
        xl?: string | number
    ) {
        this.values = {
            sm, md, lg, xl
        }
    }

    sm(): string | number | any {
        return this.values.sm;
    }

    md(): string | number | any {
        return this.values.md || this.values.sm;
    }

    lg(): string | number | any {
        return this.values.lg || this.values.md || this.values.sm;
    }

    xl(): string | number | any {
        return this.values.xl || this.values.lg || this.values.md || this.values.sm;
    }
}

export const mediaSet = (
    sm: string | number,
    md?: string | number,
    lg?: string | number,
    xl?: string | number
) => new MediaSet(sm, md, lg, xl);
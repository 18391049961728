import React, { useState } from 'react';
import { Box, BoxCaption } from '../box.component';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { AppActions } from '../../../state/interfaces';
import {
    imageSlideshowDynamicImageClassName,
    imageSlideshowBoxClassName,
    imageSlideshowBoxCursorDefaultClassName,
    imageSlideshowButtonListClassName,
    imageSlideshowButtonDirectionClassName,
    imageSlideshowButtonNumberClassName,
    imageSlideshowButtonNumberActiveClassName,
    imageSlideshowCaptionClassName
} from './image-slideshow-box.styles';
import { classes } from 'typestyle';

interface ImageSlideshowBoxProps extends AppActions {
    box: BoxData,
    linkTo?: string
}

const ImageSlideshowBox: React.FC<ImageSlideshowBoxProps> = ({
    box,
    setAppState,
    linkTo
}) => {
    const [currentImageIndex, changeCurrentImageIndex] = useState(box.image_thumbnail_index || 0);
    const imageDataMaxIndex = (box.image_list?.length || 0) - 1;
    const selectedImageData = box.image_list ? box.image_list[currentImageIndex] : null;
    const isFullScreen = !linkTo;

    const goTo = (choosenIndex: number) => {
        if (imageDataMaxIndex > -1) {
            const validatedChoosenIndex = Math.min(Math.max(choosenIndex, 0), imageDataMaxIndex);

            if (currentImageIndex !== validatedChoosenIndex) {
                changeCurrentImageIndex(validatedChoosenIndex);
            }
        }
    };

    return (
        <Box
            className={ classes(imageSlideshowBoxClassName, isFullScreen && imageSlideshowBoxCursorDefaultClassName) }
            linkTo={ linkTo }
            onClick={ linkTo ? () => {
                setAppState({
                    modalBox: box,
                    modalCustomContent: false
                });
            } : undefined }
            children={
                <>
                    { selectedImageData && (
                        <div
                            className={ imageSlideshowDynamicImageClassName }
                            title={ selectedImageData.alt }
                            style={ {backgroundImage: `url(${selectedImageData.url})`} }
                        />
                    ) }
                    { !isFullScreen && (
                        <BoxCaption
                            className={ imageSlideshowCaptionClassName }
                            name='See gallery'
                            seeBtn={ () => {} }/>
                    ) }
                    { isFullScreen && (
                        <ul className={ imageSlideshowButtonListClassName }>
                            <li>
                                <button type='button' onClick={ () => goTo(currentImageIndex - 1) } className={ imageSlideshowButtonDirectionClassName }>
                                    { '<' }
                                </button>
                            </li>
                            { box.image_list?.map((imageData, index) => (
                                <li key={ index }>
                                    <button
                                        type='button'
                                        title={ `Image ${index + 1}` }
                                        className={ classes(imageSlideshowButtonNumberClassName, currentImageIndex === index && imageSlideshowButtonNumberActiveClassName) }
                                        onClick={ () => goTo(index) }/>
                                </li>
                            )) }
                            <li>
                                <button type='button' onClick={ () => goTo(currentImageIndex + 1) } className={ imageSlideshowButtonDirectionClassName }>
                                    { '>' }
                                </button>
                            </li>
                        </ul>
                    ) }
                </>
            }
        />
    );
};

export default connectAppActions()(ImageSlideshowBox)
import React from 'react';
import { COLOR_BACKGROUND } from '../../style/constants';
import { 
    readMoreClassName,
    readMoreButonPadClassName,
    readMoreButtonClassName,

    getGradient
 } from './read-more.styles';

interface ReadMoreProps {
    onChangeOverflow?(newStatus: boolean): void,
    fadeOutColor?: string,
    showButton?: boolean,
    children?: React.ReactNode
}

interface ReadMoreState {
    showButton: boolean
}

const hasOverflow = (contentElement: HTMLDivElement): boolean =>{
    
    return contentElement.scrollHeight > contentElement.offsetHeight
} 

export class ReadMore extends React.PureComponent<ReadMoreProps, ReadMoreState> {
    private contentRef: React.RefObject<HTMLDivElement> = React.createRef();
    public state: ReadMoreState = {
        showButton: true
    };

    componentDidMount() {
        this.checkOverflow();
    }

    checkOverflow() {
        const {
            onChangeOverflow,
            showButton
        } = this.props;

        if (this.contentRef.current) {
            const currentStatus = this.props.showButton === undefined ? this.state.showButton : this.props.showButton;
            const newStatus = hasOverflow(this.contentRef.current);

            if (newStatus !== currentStatus) {
                if (showButton === undefined) {
                    this.setState({
                        showButton: newStatus
                    });
                }
                else if (onChangeOverflow) {
                    onChangeOverflow(newStatus);
                }
            }
        }
    }

    render() {
        const {
            children,
            fadeOutColor = COLOR_BACKGROUND,
            showButton = this.state.showButton
        } = this.props;


        return (
            <div className={ readMoreClassName } ref={ this.contentRef }>
                { children }
                { showButton && (
                    <div className={ readMoreButonPadClassName } style={ {
                        background: getGradient(fadeOutColor)
                    } }>
                        <button type="button" className={ readMoreButtonClassName }>
                            Read More
                        </button>
                    </div>
                ) }
            </div>
        );
    }
}
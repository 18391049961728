import { AppState, ReducerAction, ModalData } from './interfaces';

const initialState: AppState = {
    loadingExhibitions: false,
    exhibitions: null,
    focusedExhibitionOrder: 0,
    selectedExhibitionAlias: null,
    layoutType: 'default',
    modalBox: null,
    modalCustomContent: false,
    menuOpen: false,
    externalLinkBox: false,
    scrollHandlerState: false
}

export default (state: AppState = initialState, {type, payload}: ReducerAction): AppState => {
    switch(type) {
        case 'SET_APP_STATE':
            return {
                ...state,
                ...payload
            }

        default:
            return state;
    }
};
import React from 'react';
import { metroLayoutClassName } from './metro.styles';

interface MetroLayoutProps  { 
    id?:string,
    children?: React.ReactNode
}
export const MetroLayout: React.FC<MetroLayoutProps> = ({children, id}) => {
    return (
        <div className={ metroLayoutClassName } id={id}>
            { children }
        </div>
    )
};
import React from 'react';
import { Box, BoxCaption } from '../box.component';
import { connectAppActions } from '../box.utils';
import { BoxData } from '../../../state/interfaces';
import { AppActions } from '../../../state/interfaces';

interface PortraitBoxProps extends AppActions {
    box: BoxData,
    linkTo?: string
}

const PortraitBox: React.FC<PortraitBoxProps> = ({
    box,
    linkTo,
    setAppState
}) => {
    return(
        <Box bgImage={ box.image_url } linkTo={ linkTo } onClick={ () => setAppState({
            modalBox: box,
            modalCustomContent: false
        }) }>
            <BoxCaption
                name="Portrait:"
                title={ box.title }
                seeBtn={ () => {} }
            />
        </Box>
    )
}

export default connectAppActions()(PortraitBox);
import { cssRule, cssRaw, media } from 'typestyle';
import { COLOR_TEXT_PRIMARY, MEDIA_LG,FONT_FAMILY_2, FONT_FAMILY_3, FONT_WEIGHT_REGULAR, FONT_WEIGHT_EXTRABOLD, MEDIA_XL, BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG, BREAKPOINT_XL } from './constants';

cssRaw(`
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;0,800;1,400;1,700&display=swap');
    //@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,400;1,700&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:bold,wght@1,400;1,700&display=swap');

    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      
      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
      }      
`);

cssRule('html, body, #root, .App-WOP', {
    position: 'relative',
    margin: 0,
    padding: 0,
    width: '100%',
    color: COLOR_TEXT_PRIMARY,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    minWidth: '320px',
    fontSize: '16px',
    lineHeight: '22px'
});

cssRule('h1, h2, h3, h4, h5, p', {
    color: COLOR_TEXT_PRIMARY,
    margin: '15px 0'
});

cssRule('h1', {
    fontSize: '38px',
    fontFamily: FONT_FAMILY_2,
    lineHeight: '38px',
    fontWeight: FONT_WEIGHT_EXTRABOLD,
    },
    media(MEDIA_LG, {
        lineHeight: '50px',
        fontSize: '50px',
    })
);

cssRule('h2', {
    fontFamily: FONT_FAMILY_2,
    fontSize: '38px',
    lineHeight: '38px',
    fontWeight: FONT_WEIGHT_EXTRABOLD,
    fontStyle:  "normal"
});

cssRule('h3', {
    fontFamily: FONT_FAMILY_2,
    fontSize: '34px',
    lineHeight: '34px',
    fontWeight: FONT_WEIGHT_EXTRABOLD,
    fontStyle:  "normal",
    $nest: {
    }
});

cssRule('p', {
    fontFamily: FONT_FAMILY_3,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: FONT_WEIGHT_REGULAR
});

cssRule('a', {
    textDecoration: "underline",
    color: COLOR_TEXT_PRIMARY
});

cssRule('strong', {
    fontSize: '20px',
    lineHeight: '24px'
})

cssRule('.image-caption', {
    fontSize: "14px",
    marginTop: "80px"
});

cssRule('.yellow', {
    color: "#E1B64F"
});

cssRule(`@media (min-width:${BREAKPOINT_SM}px)`, {
    $nest: {
    }
});

cssRule(`@media (min-width:${BREAKPOINT_MD}px)`, {
    $nest: {
    }
});

cssRule(`@media (min-width:${BREAKPOINT_LG}px)`, {
    $nest: {
        'p': {
            fontSize: '18px',
            lineHeight: '26px',
        },
        'strong': {
            fontSize: '28px',
            lineHeight: '28px'
        },
        'h2': {
            fontSize: '50px',
            lineHeight: '50px'
        },
        'h3': {
            fontSize: '40px',
            lineHeight: '40px'
        }
    }
});

cssRule(`@media (min-width:${BREAKPOINT_XL}px)`, {
    $nest: {
    }
});

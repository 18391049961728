import { media, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { COLOR_PRIMARY,FONT_FAMILY_4,
    COLOR_BACKGROUND, 
    COLOR_TEXT_PRIMARY, 
    MEDIA_MD_ONLY, MEDIA_XS_ONLY, MEDIA_LG, MEDIA_MD, MEDIA_L} from '../../../style/constants';
import Color from 'color';

const TEXT_BOX_BG_COLOR = Color(COLOR_PRIMARY).lighten(4.7).hex();

export const getGradient = (color: string): string =>
    `linear-gradient(0deg, ${color} 40%, ${Color(color).fade(1)} 100%)`;


// Base style

const eventBoxInnerCSS: NestedCSSProperties = {
    height: '100%',
    overflow: 'hidden'
};

const eventBoxButtonPadCSS: NestedCSSProperties = {
    position: 'absolute',
    bottom: '-1px',
    left: 0,
    right: 0,
    height: '38px',
    overflow: 'visible',
    boxSizing: 'content-box',
    background: `linear-gradient(0deg, ${TEXT_BOX_BG_COLOR} 40%, ${Color(TEXT_BOX_BG_COLOR).fade(1)} 100%)`,
    padding: '100px 0 0 0'
};

const eventBoxButtonCSS: NestedCSSProperties = {
    display: 'block',
    position: 'relative',
    margin: '0 auto',
    fontStyle: 'italic',
    height: '38px',
    width: 'auto',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    boxShadow: `${COLOR_PRIMARY} 0px 0px 8px -4px`,
    outline: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    padding: '10px 15px'
};

// Export

export const eventBoxexternalLinkButtonClassName = style (
    media(MEDIA_MD_ONLY, {
        visibility: 'visible',
        opacity: 1,
    }),
    media(MEDIA_XS_ONLY, {
        visibility: 'visible',
        opacity: 1,
    }),
    media(MEDIA_L, {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s linear 300ms, opacity 300ms',
    }),
)

export const eventBoxClassName = style(
    {
        $debugName: 'textBoxClassName',

        backgroundColor: TEXT_BOX_BG_COLOR,
        color: COLOR_TEXT_PRIMARY,
        $nest: {
            '& > div': {
                margin: '0px',
                $nest:{
                    'p':{
                        fontFamily: FONT_FAMILY_4,
                        $nest:{
                            'strong':{
                                fontSize: '24px',
                            }
                        }
                    }
                }
            },
            '&:hover': {
                [`.${eventBoxexternalLinkButtonClassName}`]:{
                    visibility: 'visible',
                    opacity: 1,
                    transition: 'visibility 0s linear 0s, opacity 300ms'
                }
            }
        },
        
    },
    media(MEDIA_MD, {
        padding: '20px'
    }),
    media(MEDIA_LG, {
        padding: '30px'
    }),
    media(MEDIA_L, {
        padding: '30px'
    })
);
export const eventBoxInnerClassName = style(eventBoxInnerCSS);
export const eventBoxContentClassName = style({
    maxHeight: '100%',
    overflow: 'hidden',
    $nest: {
        'h4': {
            fontSize: '22px!important'
        }
    }
});
export const eventBoxButtonPadClassName = style(eventBoxButtonPadCSS);
export const eventBoxButtonClassName = style(eventBoxButtonCSS);

export const eventBoxTypeTitleClassName = style({
    backgroundColor: COLOR_BACKGROUND,

    $nest: {
        [`.${eventBoxButtonPadClassName}`]: {
            background: getGradient(COLOR_BACKGROUND)
        }
    }
});

export const eventBoxTypeBodyClassName = style({
    backgroundColor: TEXT_BOX_BG_COLOR,

    $nest: {
        [`.${eventBoxButtonPadClassName}`]: {
            background: getGradient(TEXT_BOX_BG_COLOR)
        }
    }
});



export const eventBoxTypeBodyContrastClassName = style({
    backgroundColor: '#365c90',


    $nest: {
        [`.${eventBoxButtonPadClassName}`]: {
            background: getGradient('#365c90'),

            $nest: {
                [`.${eventBoxButtonClassName}`]: {
                    color: COLOR_BACKGROUND
                }
            }

        },
        [`.${eventBoxContentClassName}`]: {
            color: COLOR_BACKGROUND,

            $nest: {
                'p': {color: COLOR_BACKGROUND},
                'a': {color: COLOR_BACKGROUND},
                'h1': {color: COLOR_BACKGROUND},
                'h2': {color: COLOR_BACKGROUND},
                'h3': {color: COLOR_BACKGROUND},
                'h4': {color: COLOR_BACKGROUND}
            }
        }
    }
});



// import { style, media } from 'typestyle';
// import { FONT_FAMILY_2, FONT_WEIGHT_BOLD, MEDIA_MD } from '../../../style/constants';

// export const eventBoxButtonPadClassName = style({
//     display: 'block',
//     position: 'absolute',
//     bottom: 0,
//     right: 0,
//     margin: 0,
//     opacity: 0,
//     pointerEvents: 'none',
//     transition: '300ms'
// });

// export const eventBoxClassName = style({
//     backgroundColor: 'rgb(212, 226, 255)',
//     transition: 'background-color 300ms',
//     boxSizing: 'border-box',
//     border: 'solid 2px #ffffff',

//     $nest: {
//         '& > div': {
//             boxSizing: 'border-box',
//             paddingBottom: '80px',
//             position: 'relative'
//         },

//         '&:hover': {
//             backgroundColor: '#bdd5ff',
//             $nest: {
//                 [`.${eventBoxButtonPadClassName}`]: {
//                     opacity: 1,
//                     pointerEvents: 'auto'
//                 }
//             }
//         }
//     }
// },
// media(MEDIA_MD, {
//     height: 'auto',
//     width: '100%',
//     margin: '5px 1% 5px 1%',
//     float: 'left',
//     position: 'relative',
//     transition: 'height 0.5s',
//     overflow: 'hidden',
//     $nest: {
//         '&:hover': {
//             zIndex:'auto',
//             height: 'calc(100% - 125px)',
//         }
//     }
// })
// );
// export const eventBoxTitleClassName = style({
//     fontFamily: FONT_FAMILY_2,
//     fontWeight: FONT_WEIGHT_BOLD,
//     fontSize: '28px'
// });
// export const eventBoxSubtitleClassName = style({
//     margin: '5px 0 20px 0',
//     fontFamily: FONT_FAMILY_2,
//     fontWeight: FONT_WEIGHT_BOLD,
//     fontSize: '16px'
// });
// export const eventBoxBodyFormatClassName = style({
//     fontSize: '18px',
//     lineHeight: '24px'
// });

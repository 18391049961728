import { style, media } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import {
    MEDIA_MD,
    SIZE_PAGE_MARGIN_X_XS,
    SIZE_PAGE_MARGIN_X_MD,
    FONT_FAMILY_2,
    FONT_WEIGHT_SEMIBOLD,
    FONT_WEIGHT_EXTRABOLD,
    COLOR_TEXT_PRIMARY,
    HEADER_HEIGHT,
    COLOR_BACKGROUND, MEDIA_LG, MEDIA_XS_ONLY, MEDIA_SM_ONLY, FONT_WEIGHT_BOLD
} from '../../style/constants';

/**
 * All devices
 */

export const headerExhibitionGuideTwoClassName = style(
    {

    },
    media(MEDIA_MD, {
    })
);

const headerPaddingX = SIZE_PAGE_MARGIN_X_XS;
const headerPaddingY = 15;

const headerInnerCSS: NestedCSSProperties = {
    height: `${HEADER_HEIGHT}px`,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: `${headerPaddingY}px ${headerPaddingX}px`,
    boxSizing: 'border-box',
    overflow: 'visible'
};

const centerAreaCSS: NestedCSSProperties = {
    flexGrow: 1,
    flexShrink: 0,
    height: '100%',
    padding: '0 15px'
};

/**
 * MD devices
 */

const mdHeaderPaddingX = SIZE_PAGE_MARGIN_X_MD;
const mdHeaderPaddingY = 15;

const headerInnerMDCSS: NestedCSSProperties = media(MEDIA_MD, {
    height: `${HEADER_HEIGHT}px`,
    padding: `${mdHeaderPaddingY}px ${mdHeaderPaddingX}px`
});

export const menuListClassName = style(
    {
        $debugName: 'menuListClassName',
        display: 'block',
        listStyle: 'none',
        padding: 0,
        margin: 0,
        textAlign: 'center',
        height: 'calc(100% - 80px)',
        position: 'fixed',
        width: '100%',
        left: 0,
        top: '80px',
        backgroundColor: '#001a52',
        transition: 'opacity 300ms',

        $nest: {
            '& > li': {
                display: 'block',
                position: 'relative',
                height: '60px',
                width: '100%',
                margin: '15px 0'
            }
        }
    },
    media(MEDIA_MD, {
        position: 'relative',
        width: 'auto',
        backgroundColor: 'transparent',
        height: '100%',
        top: 0,

        $nest: {
            '& > li': {
                display: 'inline-block',
                height: '100%',
                width: 'auto',
                margin: 0,

                $nest: {
                    [`&.${headerExhibitionGuideTwoClassName}`]: {
                        display: 'none'
                    }
                }
            }
        }
    })
);

export const headerClassName = style(
    {
        display: 'block',
        position: 'fixed',
        height: `${HEADER_HEIGHT}px`,
        width: '100%',
        background: 'white',
        boxShadow: '#0208192e 10px 0px 10px 0px',
        zIndex: 1000
    },
    media(MEDIA_MD, {
        height: `${HEADER_HEIGHT}px`
    }),
    media(MEDIA_XS_ONLY, {
        $nest: {
            [`.${menuListClassName}`]: {
                opacity: 0,
                pointerEvents: 'none'
            }
        }
    }),
    media(MEDIA_SM_ONLY, {
        $nest: {
            [`.${menuListClassName}`]: {
                opacity: 0,
                pointerEvents: 'none'
            }
        }
    })
);
export const headerInnerClassName = style(headerInnerCSS, headerInnerMDCSS);
export const brandAreaClassName = style(
    {
        flexGrow: 0,
        flexShrink: 0,
        position: 'relative',
        height: '100%',

        $nest: {
            '& > a': {
                display: 'block',
                height: '100%',
                position: 'relative',
                width: 'auto',

                $nest: {
                    '& > img': {
                        display: 'block',
                        position: 'relative',
                        height: '70%',
                        width: 'auto',
                        border: 'none',
                        margin: '10px 0 0 0',
                        padding: 0
                    }
                }
            }
        }
    },
    media(MEDIA_MD, {
        $nest: {
            '& > a': {
                $nest: {
                    '& > img': {
                        height: '100%',
                        margin: 0
                    }
                }
            }
        }
    })
);
export const logoDefaultClassName = style({
    display: 'block'
});
export const logoMenuOpenClassName = style({
    display: 'none !important'
});
export const centerAreaClassName = style(centerAreaCSS);
export const headerExhibitionGuideClassName = style(
    {
        display: 'none'
    },
    media(MEDIA_MD, {
        display: 'inline-block',
        position: 'relative',
        height: '100%',

        $nest: {
            'img': {
                height: '100%',
                margin: 0
            }
        }
    })
);
export const navClassName = style(
    {
        $debugName: 'navClassName',
        flexGrow: 0,
        flexShrink: 0,

        $nest: {
            '& > button': {
                margin: '5px 0px'
            }
        }
    },
    media(MEDIA_MD, {
        $nest: {
            '& > button': {
                display: 'none'
            }
        }
    })
);

export const menuListOptionClassName = style(
    {
        $debugName: 'menuListOptionClassName',
        display: 'flex',
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        fontFamily: FONT_FAMILY_2,
        fontWeight: FONT_WEIGHT_SEMIBOLD,
        textTransform: 'uppercase',
        fontSize: '36px',
        alignItems: 'center',
        padding: '5px 15px',
        boxSizing: 'border-box',
        textDecoration: 'none',
        color: COLOR_BACKGROUND,
        textAlign: 'center',
        justifyContent: 'center',
        border: 'none',
        backgroundColor: 'transparent',
        outline: 'none',

        $nest: {
            '&.active': {
                fontWeight: FONT_WEIGHT_EXTRABOLD,
                color: '#56b5e5'
            },
            '&:hover, &:active, &:focus': {
                // color: COLOR_TEXT_PRIMARY,
                fontWeight: FONT_WEIGHT_EXTRABOLD,
                color: '#56b5e5'
            }
        }
    },
    media(MEDIA_MD, {
        fontSize: '16px',
        color: COLOR_TEXT_PRIMARY
    })
);

export const headerTransparentClassName = style(
    {
        background: 'transparent',
        boxShadow: 'none',
    },
    media(MEDIA_MD, {
        $nest: {
            [`.${menuListClassName}`]: {
                $nest: {
                    '& > li': {
                        $nest: {
                            [`& > .${menuListOptionClassName}`]: {
                                color: COLOR_BACKGROUND
                            }
                        }

                    }
                }
            }
        }
    })
);

export const shareTooltipClassName = style(
    {
        maxWidth: '480px',
        boxSizing: 'border-box',
        background: 'white',
        padding: '30px',
        right: 0,
        borderRadius: '5px',
        overflow: 'visible',
        boxShadow: 'black 1px 1px 8px -4px',
        border: 'solid 1px #e4e4e4',
        textAlign: 'left',


        position: 'relative',
        top: 0,
        margin: '0 auto',
        width: '100%',



        $nest: {
            '&:before': {
                content: '""',
                display: 'block',
                width: '30px',
                height: '30px',
                backgroundColor: COLOR_BACKGROUND,
                position: 'absolute',
                bottom: '100%',
                right: '50%',
                margin: '0 -15px -15px 0',
                transform: 'rotate(45deg)',
                borderLeft: 'solid gainsboro 1px',
                borderTop: 'solid gainsboro 1px'
            }
        }
    },
    media(MEDIA_MD, {
        position: 'absolute',
        top: '100%',
        margin: '15px 0 0 0',
        width: '480px',

        $nest: {
            '&:before': {
                right: '27px',
                margin: '0 0 -15px 0'
            }
        }
    })
);

export const shareTooltipTitleClassName = style(
    {
        textTransform: 'uppercase',
        fontWeight: FONT_WEIGHT_BOLD
    }
);

export const shareTooltipListClassName = style(
    {
        position: 'relative',
        width: '100%',
        padding: 0,
        margin: 0,
        listStyle: 'none',

        $nest: {
            '&:before, &:after': {
                display: 'block',
                content: '""',
                clear: 'both'
            },

            '& > li': {
                display: 'block',
                float: 'left',
                width: '100%',
                margin: '15px 0 0 0',
                padding: 0,

                $nest: {
                    '& > button': {
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        textAlign: 'left',

                        $nest: {
                            '& > img': {
                                margin: '0 15px 0 0'
                            }
                        }
                    }
                }
            }
        }
    },
    media(MEDIA_MD, {
        $nest: {
            '& > li': {
                width: '50%'
            }
        }
    })
);

export const menuHoverClassName = style(
    {
        $debugName: 'menuHoverClassName',
        fontWeight: FONT_WEIGHT_EXTRABOLD,
        color: COLOR_TEXT_PRIMARY
    
    }
);

export const menuOpenedClassName = style(
    // {
    //     background: '#001a52',

    //     $nest: {
    //         ['.' + logoDefaultClassName]: {
    //             display: 'none !important'
    //         },
    //         ['.' + logoMenuOpenClassName]: {
    //             display: 'block !important'
    //         }
    //     }
    // },

    media(MEDIA_XS_ONLY, {
        background: '#001a52',
        transition: 'background 300ms',

        $nest: {
            ['.' + logoDefaultClassName]: {
                display: 'none !important'
            },
            ['.' + logoMenuOpenClassName]: {
                display: 'block !important'
            },
            [`.${menuListClassName}`]: {
                opacity: 1,
                pointerEvents: 'auto'
            }
        }
    }),
    media(MEDIA_SM_ONLY, {
        background: '#001a52',
        transition: 'background 300ms',

        $nest: {
            ['.' + logoDefaultClassName]: {
                display: 'none !important'
            },
            ['.' + logoMenuOpenClassName]: {
                display: 'block !important'
            },
            [`.${menuListClassName}`]: {
                opacity: 1,
                pointerEvents: 'auto'
            }
        }
    })
);

export const menuClosedClassName = style(
    {
    },
    media(MEDIA_XS_ONLY, {
        $nest: {
            [`.${menuListClassName}`]: {
                opacity: 0,
                pointerEvents: 'none'
            }
        }
    }),
    media(MEDIA_SM_ONLY, {
        $nest: {
            [`.${menuListClassName}`]: {
                opacity: 0,
                pointerEvents: 'none'
            }
        }
    })
);

import React, { useEffect } from 'react';
import { Box, BoxCaption } from '../box.component';
import {
    quoteBoxDescriptionClassName,
    quoteBoxBodyClassName,
    quoteBoxGradientClassName,
    quoteBoxOddThemeClassName,
    quoteBoxEvenThemeClassName,
    quoteBoxReadMoreBtnClassName
} from './quote-box.styles';
import { connectAppActions } from '../box.utils';
import { AppActions, BoxData } from '../../../state/interfaces';
import { ReadMore } from '../../read-more/read-more.component';

interface QuoteBoxProps extends AppActions {
    description?: string,
    body?: string,
    externalLink?: string,
    shareBtn?: boolean,
    image?: string,
    box: BoxData,
    linkTo?: string
}

let odd: boolean = false;

const checkContentOverflow = (ref: React.RefObject<HTMLDivElement>): boolean => {
    if (ref) {
        const contentElement = ref.current;

        return (
            !!contentElement && (
                contentElement.scrollHeight > contentElement.offsetHeight
            )
        );
    }

    return true;
}

const QuoteBox: React.FC<QuoteBoxProps> = ({
    box,
    description,
    body = '',
    externalLink,
    shareBtn = false,
    image,
    setAppState,
    linkTo
}) => {
    odd = !odd;

    const boxClassNames = odd ? quoteBoxOddThemeClassName : quoteBoxEvenThemeClassName;
    const readMoreColor = odd ? '#365c90' : '#657db1';

    return (
        <Box bgImage={ image } className={ boxClassNames } linkTo={ linkTo } onClick={ () => setAppState({
            modalCustomContent: false,
            modalBox: box
        }) }>
            <ReadMore fadeOutColor={ readMoreColor }>
                { description && (
                    <div className={ quoteBoxDescriptionClassName }
                         dangerouslySetInnerHTML={ {__html: description} }/>
                ) }
                <div
                    className={ quoteBoxBodyClassName }
                    dangerouslySetInnerHTML={ {__html: body} }/>
            </ReadMore>

            { (externalLink || shareBtn) && (
                <BoxCaption
                    linkBtn={ externalLink }
                    shareBtn={ shareBtn ? '#' : undefined }/>
            ) }
        </Box>
    )
};

export default connectAppActions()(QuoteBox);

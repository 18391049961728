import ImageBoxComponent from './image-box/image-box.component';
import VideoBoxComponent from './video-box/video-box.component';
import PortraitBoxComponent from './portrait-box/portrait-box.component';
import QuoteBoxComponent from './quote-box/quote-box.component';
import TextBoxComponent from './text-box/text-box.component';
import BodyBoxComponent from './body-box/body-box.component';
import WebsiteBoxComponent from './website-box/website-box.component';
import DocumentBoxComponent from './document-box/document-box.component';

export { HeadBox } from './head-box/head-box.component';
export { TitleBox } from './title-box/title-box.component';
export const BodyBox = BodyBoxComponent;
export const VideoBox = VideoBoxComponent;
export const WebsiteBox = WebsiteBoxComponent;
export const DocumentBox = DocumentBoxComponent;
export const ImageBox = ImageBoxComponent;
export const PortraitBox = PortraitBoxComponent;
export const QuoteBox = QuoteBoxComponent;
export const TextBox = TextBoxComponent;
export { CaptionBox } from './caption-box/caption-box.component';
import { connect } from 'react-redux';
import { AppState, AppActions, PageData } from '../../../state/interfaces';
import { setAppState } from '../../../state/actions';

export interface PageStateProps extends AppActions {
    pages?: PageData[] | null
}

const mapStateToProps = (state: any): AppState => {
    return {
        pages: state.AppReducer.pages
    }
};

const mapDispatchToProps: AppActions = {
    setAppState
};

export default connect(mapStateToProps, mapDispatchToProps);
import React from 'react';
import { Box } from '../box.component';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { AppActions } from '../../../state/interfaces';

interface ImageBoxProps extends AppActions {
    box: BoxData,
    linkTo?: string,
    onClick?: () => void,
    id?:string
}

const ImageBox: React.FC<ImageBoxProps> = ({
    box,
    linkTo,
    setAppState,
    onClick, 
    id
}) => (
    <Box  bgImage={ box.image_url } linkTo={ linkTo } onClick={ () => {
        setAppState({
            modalBox: box,
            modalCustomContent: false
        });

        if (onClick) {
            onClick()
        }
    } }/>
);

export default connectAppActions()(ImageBox)
import { connect } from 'react-redux';
import { setAppState } from '../../../state/actions';
import { AppState } from '../../../state/interfaces';

export interface HomeStateProps {
    setAppState(state: AppState): void
}

const mapDispatchToProps = {
    setAppState
};

export const connectState = connect(null, mapDispatchToProps);
import {
    AppState,
    BoxData,
    BoxDataType,
    ExhibitionData,
    PageData,
    MetroBlockData
} from './state/interfaces';

export const parseExhibition = (exhibitionData: any): ExhibitionData => {
    return exhibitionData;
};

export const parsePage = (pageData: any): PageData => {
    return {
        alias: pageData.alias || '404',
        title: pageData.title || '',
        body: pageData.body || '',
        image_url: pageData.image_url || null,
        metro_layout: pageData.metro_layout
    }
};

export const parseExhibitions = (exhibitionsData: any): ExhibitionData[] => {
    return exhibitionsData.map(parseExhibition);
};

export const parsePages = (pagesData: any): PageData[] => {
    return pagesData.map(parsePage);
}

export const parseData = (data: any): AppState => {
    return {
        exhibitions: parseExhibitions(data.exhibitions),
        pages: parsePages(data.pages)
    }
};

export const zeroPad = (number: number, size: number) => {
    let s = String(number);

    while (s.length < (size || 2)) {
        s = '0' + s;
    }

    return s;
};

export const addAliasToUnknownData = (exhibitionDataList: ExhibitionData[]): void => {
    let exhibitionCount = 0;
    let boxCount = 0;

    exhibitionDataList.forEach(exhibitionData => {
        exhibitionCount++;

        if (!exhibitionData.alias) {
            exhibitionData.alias = `exhibition-${exhibitionCount}`;
        }

        exhibitionData.page?.metro_layout?.metro_blocks.forEach((metroBlock:MetroBlockData) => {
            if (metroBlock.box) {
                boxCount++;

                if (!metroBlock.box.alias) {
                    metroBlock.box.alias = String(boxCount);
                }
            }
            else if (metroBlock.child_blocks) {
                metroBlock.child_blocks.forEach((childBlockData:MetroBlockData) => {
                    if (childBlockData.box) {
                        boxCount++;

                        if (!childBlockData.box.alias) {
                            childBlockData.box.alias = String(boxCount);
                        }
                    }
                });
            }
            else if (metroBlock.child_blocks_left) {
                metroBlock.child_blocks_left.forEach((childBlockData:MetroBlockData) => {
                    if (childBlockData.box) {
                        boxCount++;

                        if (!childBlockData.box.alias) {
                            childBlockData.box.alias = String(boxCount);
                        }
                    }
                });
            }
        });
    });
};

export const addFrontEndMetadataToExhibitionList = (exhibitionDataList: ExhibitionData[]): void => {
    const navigableBoxTypes: BoxDataType[] = [
        'image', 'video', 'body', 'portrait', 'text', 'quote'
    ];
    let previousExhibition: ExhibitionData | null = null;
    let previousBox: BoxData | null = null;

    exhibitionDataList.forEach((exhibitionData, exhibitionIndex) => {
        const currentExhibitionPath: string = `/exhibitions/${exhibitionData.alias}/`;
        let boxCountInExhibition = -1;

        if (exhibitionData.page) {
            exhibitionData.page.$firstUrl = currentExhibitionPath + 'introduction';
        }
        
        if (exhibitionData.page && exhibitionData.page.metro_layout && exhibitionData.page.metro_layout.metro_blocks.length) {
            exhibitionData.page.metro_layout.metro_blocks.forEach((metroBlock, parentIndex) => {
                if (exhibitionData.page && exhibitionData.page.metro_layout && metroBlock.box && (navigableBoxTypes.indexOf(metroBlock.box.type) !== -1)) {
                    boxCountInExhibition++;
                    metroBlock.box.$currentBoxUrl = currentExhibitionPath + metroBlock.box.alias;

                    if (previousBox) {
                        if (boxCountInExhibition === 0) {
                            const exhibitionFirstBoxUrl: string = ((urlParts: string[]): string => {
                                urlParts[3] = 'introduction';
                                return urlParts.join('/');
                            })(metroBlock.box.$currentBoxUrl.split('/'));

                            previousBox.$nextBoxUrl = exhibitionFirstBoxUrl
                            metroBlock.box.$previousBoxUrl = exhibitionFirstBoxUrl;

                            if (exhibitionData.page) {
                                exhibitionData.page.$nextBoxUrl = metroBlock.box.$currentBoxUrl;
                                exhibitionData.page.$previousBoxUrl = previousBox.$currentBoxUrl;
                            }
                        }
                        else {
                            previousBox.$nextBoxUrl = metroBlock.box.$currentBoxUrl;
                            metroBlock.box.$previousBoxUrl = previousBox.$currentBoxUrl;
                        }
                    }
                    else if (previousExhibition && previousExhibition.page) {
                        metroBlock.box.$previousBoxUrl = currentExhibitionPath + 'introduction';
                        exhibitionData.page.$nextBoxUrl = metroBlock.box.$currentBoxUrl;
                    }

                    previousBox = metroBlock.box;
                }
                else if (metroBlock.child_blocks) {
                    metroBlock.child_blocks.forEach((childBlockData, childIndex) => {
                        if (exhibitionData.page && exhibitionData.page.metro_layout && childBlockData.box && (navigableBoxTypes.indexOf(childBlockData.box.type) !== -1)) {
                            boxCountInExhibition++;
                            childBlockData.box.$currentBoxUrl = currentExhibitionPath + childBlockData.box.alias;
            
                            if (previousBox) {
                                if (boxCountInExhibition === 0) {
                                    const exhibitionFirstBoxUrl: string = ((urlParts: string[]): string => {
                                        urlParts[3] = 'introduction';
                                        return urlParts.join('/');
                                    })(childBlockData.box.$currentBoxUrl.split('/'));
            
                                    previousBox.$nextBoxUrl = exhibitionFirstBoxUrl
                                    childBlockData.box.$previousBoxUrl = exhibitionFirstBoxUrl;

                                    if (exhibitionData.page) {
                                        exhibitionData.page.$nextBoxUrl = childBlockData.box.$currentBoxUrl;
                                        exhibitionData.page.$previousBoxUrl = previousBox.$currentBoxUrl;
                                    }
                                }
                                else {
                                    previousBox.$nextBoxUrl = childBlockData.box.$currentBoxUrl
                                    childBlockData.box.$previousBoxUrl = previousBox.$currentBoxUrl;
                                }
                            }
                            else if (previousExhibition && previousExhibition.page) {
                                childBlockData.box.$previousBoxUrl = currentExhibitionPath + 'introduction';
                                exhibitionData.page.$nextBoxUrl = childBlockData.box.$currentBoxUrl;
                            }
            
                            previousBox = childBlockData.box;
                        }
                    });
                }
                else if (metroBlock.child_blocks_left) {
                    metroBlock.child_blocks_left.forEach((childBlockData, childIndex) => {
                        if (exhibitionData.page && exhibitionData.page.metro_layout && childBlockData.box && (navigableBoxTypes.indexOf(childBlockData.box.type) !== -1)) {
                            boxCountInExhibition++;
                            childBlockData.box.$currentBoxUrl = currentExhibitionPath + childBlockData.box.alias;
            
                            if (previousBox) {
                                if (boxCountInExhibition === 0) {
                                    const exhibitionFirstBoxUrl: string = ((urlParts: string[]): string => {
                                        urlParts[3] = 'introduction';
                                        return urlParts.join('/');
                                    })(childBlockData.box.$currentBoxUrl.split('/'));
            
                                    previousBox.$nextBoxUrl = exhibitionFirstBoxUrl
                                    childBlockData.box.$previousBoxUrl = exhibitionFirstBoxUrl;

                                    if (exhibitionData.page) {
                                        exhibitionData.page.$nextBoxUrl = childBlockData.box.$currentBoxUrl;
                                        exhibitionData.page.$previousBoxUrl = previousBox.$currentBoxUrl;
                                    }
                                }
                                else {
                                    previousBox.$nextBoxUrl = childBlockData.box.$currentBoxUrl
                                    childBlockData.box.$previousBoxUrl = previousBox.$currentBoxUrl;
                                }
                            }
                            else if (previousExhibition && previousExhibition.page) {
                                childBlockData.box.$previousBoxUrl = currentExhibitionPath + 'introduction';
                                exhibitionData.page.$nextBoxUrl = childBlockData.box.$currentBoxUrl;
                            }
            
                            previousBox = childBlockData.box;
                        }
                    });
                }
            });
        }
        else {
            const nextExhibitionData = exhibitionDataList[exhibitionIndex + 1];

            if (exhibitionData.page && nextExhibitionData && nextExhibitionData.page) {
                exhibitionData.page.$nextBoxUrl = `/exhibitions/${nextExhibitionData.alias}/introduction`;
                nextExhibitionData.page.$previousBoxUrl = currentExhibitionPath + 'introduction';
            }
        }

        previousExhibition = exhibitionData;
    });
};
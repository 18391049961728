import { media, style } from 'typestyle';
import {
    FONT_FAMILY_3,
    COLOR_BACKGROUND,
    FONT_FAMILY_1,
    FONT_FAMILY_2,
    FONT_WEIGHT_BOLD,
    COLOR_PRIMARY, MEDIA_LG_ONLY,MEDIA_XL, MEDIA_LG, MEDIA_MD, MEDIA_SM, MEDIA_XS_ONLY, MEDIA_SM_ONLY, MEDIA_MD_ONLY
} from '../../style/constants';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { __String } from 'typescript';

export const boxPageClassName = style({
    height: '100%',
    overflow: 'visible'
});

export const boxPageColumnsWrapperClassName = style(
    {
        display: 'block',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100%'
    },
    media(MEDIA_LG, {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'center'
    }),
);

export const boxPageOneColumnClassName = style(
    {
        $debugName: 'boxPageOneColumn',
        flexGrow: 0,
        flexShrink: 0,
        padding: '75px 15px 120px 15px',
        maxWidth: 'minWidth: 320px',
        width: '80%',
        margin: '0 auto',
        minWidth: '320px',
        boxSizing: 'border-box',
        minHeight: '100%'
    },
    media(MEDIA_LG, {
        minHeight: 'auto'
    })
);

export const boxPageLeftColumnClassName = style(
    {
        $debugName: 'boxPageLeftColumn',
        width: '100%'
    },
    media(MEDIA_LG, {
        width: '50%',
        flexGrow: 0,
        flexShrink: 0
    })
);


export const boxPageRightColumnClassName = style(
    {
        $debugName: 'boxPageRightColumn',
        width: '100%',
        boxSizing: 'border-box',
        padding: '20px 20px 120px 20px',
        $nest: {
            '.descriptionQuote': {
                fontSize: '24px',
                fontStyle: 'italic',
                fontFamily: FONT_FAMILY_3,
            },
            'h1':{
                fontStyle: 'normal',
            }
        }
    },
    media(MEDIA_LG, {
        width: '50%',
        flexGrow: 0,
        flexShrink: 0,
        minHeight: '100%',
        padding: '60px 60px 120px 60px'
    })
);

export const boxPageColumnMediaClassName = style(
    {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        $debugName: 'boxPageColumnMedia',
        backgroundColor: COLOR_PRIMARY,
        height: 'auto',
        width: 'auto',
        flexGrow: 1,
        flexShrink: 1,
        minHeight: '500px',

        $nest: {
            '> img': {
                position: 'absolute',
                display: 'block',
                width: '25%',
                maxWidth: '360px',
                margin: '0px 15px'
            }
        }
    },
    media(MEDIA_MD_ONLY, {
        $nest: {
            'iframe': {
                height: '500px',
                marginBottom: 0
            }
        }
    }),
    media(MEDIA_SM_ONLY, {
        $nest: {
            'iframe': {
                height: '500px',
                marginBottom: 0
            }
        }
    }),
    media(MEDIA_XS_ONLY, {
        $nest: {
            'iframe': {
                height: '500px',
                marginBottom: 0
            }
        }
    })
);

export const boxPageColumnMediaContainClassName = style(
    {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: '#CCC'
    }
);
export const boxPageColumnBody = style (
    {
        fontSize:'20px',
        lineHeight:'25px',
        fontFamily: FONT_FAMILY_3,
    }
)
export const boxPageColumnTextClassName = style(
    {
        $debugName: 'boxPageColumnText',
        width: '100%',
        flexGrow: 0,
        flexShrink: 0,
        minHeight: '100%',
        boxSizing: 'border-box',
        padding: '60px 20px 120px 20px',
        $nest: {
            'h1':{
                fontStyle:'normal',
            },
            'p':{
                fontSize: '20px',
                lineHeight:'25px',
                fontFamily: FONT_FAMILY_3,
            }
        }
    },
    media(MEDIA_LG, {
        width: '380px'
    }),
    media(MEDIA_XL, {
        width: '512px',
        padding: '60px 60px 120px 60px'
    })
);

export const boxPageVideoClassName = style({
    width: '100%',
    height: 'calc(100% - 120px)',
    margin: '60px 0',
    $nest: {
        'h1':{
            fontStyle:'normal',
        }
    }
});

export const boxPageButtonPadClassName = style({
    $debugName: 'boxPageButtonPad',
    position: 'relative',
    width: '210px',
    height: '100px',
    right: 0,
    float: 'right',
    textAlign: 'right',
    margin: '-108px 0px 0 0',


    $nest: {
        '& > a': {
        }
    }
});

// each page

export const boxPageQuoteFormatClassName = style({
    fontFamily: FONT_FAMILY_3,
    fontStyle: 'italic',
    backgroundColor: '#365c90',
    color: COLOR_BACKGROUND,
    fontSize: '35px',
    minHeight: '100%',
    padding: '120px 60px',
    lineHeight: '44px',
    boxSizing: 'border-box',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '40%',
    backgroundPositionX: '15px',
    $nest: {
        'strong':{
            fontSize: '31px',
        }
    }
});


export const boxPageTextFormatClassName = style(
    {
        minHeight: '100%',
        // padding: '120px 60px',
        boxSizing: 'border-box',
        fontFamily: FONT_FAMILY_1,
        fontStyle: 'italic',
        color: COLOR_BACKGROUND,
        fontSize: '28px',
        lineHeight: '32px',

        $nest: {
            '& p, span, h1, h2, h3': {
                fontFamily: FONT_FAMILY_1,
                fontStyle: 'italic',
                color: COLOR_BACKGROUND,
                fontSize: '28px',
                lineHeight: '32px'
            },
            '& span': {
                color: '#E1B64F',
            }
        }
    },
    media(MEDIA_MD, {
        fontSize: '34px',
        lineHeight: '40px',

        $nest: {
            '& p, & span, & h1, & h2, &h3': {
                fontSize: '34px',
                lineHeight: '40px'
            }
        }
    }),
    media(MEDIA_LG, {
        fontSize: '52px',
        lineHeight: '46px',

        $nest: {
            '& p, & span, & h1, & h2, &h3': {
                fontSize: '52px',
                lineHeight: '46px'
            }
        }
    })
);

// PORTRAIT

export const boxPagePortraitGalleryClassName = style({
    display: 'block',
    width: '100%',

    $nest: {
        '&:before, &:after': {
            display: 'block',
            content: '""',
            clear: 'both'
        }
    }
});

export const boxPagePortraitGalleryItemInnerClassName = style({
    $debugName: 'boxPagePortraitGalleryItemInner',
    display: 'block',
    width: '100%',
    overflow: 'hidden'
});

const boxPagePortraitGalleryItemCSS: NestedCSSProperties = {
    display: 'block',
    float: 'left',
    height: 0,
    overflow: 'hidden',
    paddingBottom: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    boxSizing: 'border-box',

    $nest: {
        [`.${boxPagePortraitGalleryItemInnerClassName}`]: {
            width: '100%',
            height: '100%'
        }
    }
};

export const boxPagePortraitGalleryImageClassName = style({
    ...boxPagePortraitGalleryItemCSS,
    $debugName: 'boxPagePortraitGalleryImage',
    width: '50%'
});

export const boxPagePortraitGalleryVideoClassName = style({
    ...boxPagePortraitGalleryItemCSS,
    $debugName: 'boxPagePortraitGalleryVideo',
    width: '50%',
    cursor: 'pointer'
});

export const boxPagePortraitGalleryQuoteClassName = style({
    display: 'block',
    float: 'left',
    height: 0,
    overflow: 'hidden',
    paddingBottom: '50%',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    boxSizing: 'border-box',
    $debugName: 'boxPagePortraitGalleryQuote',
    width: '100%'},
    media(MEDIA_XS_ONLY, {
        height: 'auto',
        padding: '0px!important',
    }),
    media(MEDIA_LG_ONLY,{
        height: 'auto',
        padding: '0px!important',
    })
);

export const boxPageVideoTitleClassName = style({
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    color: COLOR_BACKGROUND,
    padding: '30px',
    boxSizing: 'border-box',
    fontFamily: FONT_FAMILY_2,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: '26px',

    $nest: {
        '& button': {
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '30px'
        },
        '& small': {
            display: 'block',
            fontSize: '16px'
        }
    }
});

export const boxPagePortraitGalleryQuoteFormatClassName = style({
    padding: '30px',
    color: COLOR_BACKGROUND,
    fontFamily: FONT_FAMILY_3,
    fontStyle: 'italic',
    fontSize: '28px',

    $nest: {
        '& *': {
            color: COLOR_BACKGROUND,
            fontFamily: FONT_FAMILY_3,
            fontStyle: 'italic',
            fontSize: '28px'
        }
    }
});

export const boxPageQuoteTitleFormatClassName = style(
    {
        
    }
);

export const boxPageQuoteBodyFormatClassName = style(
    {
        fontSize: '20px',
        lineHeight:'25px',
        fontFamily: FONT_FAMILY_3,
    }
);
import React from 'react';
import {
    NavLink,
    Link
} from 'react-router-dom';
import {
    headerClassName,
    headerTransparentClassName,
    headerInnerClassName,
    navClassName,
    menuListClassName,
    menuListOptionClassName,
    brandAreaClassName,
    centerAreaClassName,
    headerExhibitionGuideClassName,
    menuOpenedClassName,
    menuClosedClassName,
    headerExhibitionGuideTwoClassName,

    shareTooltipClassName,
    shareTooltipTitleClassName,
    shareTooltipListClassName,
    menuHoverClassName,
    logoDefaultClassName,
    logoMenuOpenClassName
} from './header.styles';
import { AppState, LayoutType, PageData } from '../../state/interfaces';
import { IconButton } from '../icon-button/icon-button.component';
import { connect } from 'react-redux';
import { setAppState } from '../../state/actions';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from 'react-share';
import { Svg } from '../svg/svg.component';
import mainLogoBlue from '../../images/Diplomacy4Peace-Logo-blue.png';
import mainLogoWhite from '../../images/Diplomacy4Peace-Logo-4x.png';

interface HeaderProps {
    layoutType?: LayoutType,
    pages: PageData[],
    menuOpen?: boolean,
    setAppState?(state: AppState): void
}

interface HeaderState {
    showShareTooltip: boolean,
    hoverLinkState: boolean
}

class Header extends React.PureComponent<HeaderProps, HeaderState> {
    public state: HeaderState = {
        showShareTooltip: false,
        hoverLinkState: false
    };

    private closeTooltipEvent: any = null;

    componentDidMount() {
        const $this = this;

        function closeTooltipEvent() {
            if ($this.state.showShareTooltip) {
                $this.setState({
                    showShareTooltip: false
                })
            }
        }

        window.addEventListener('click', closeTooltipEvent);

        this.closeTooltipEvent = closeTooltipEvent;
    }


    componentWillUnmount() {
        window.removeEventListener('click', this.closeTooltipEvent);
    }

    private toggleShareTooltip() {
        this.setState({
            showShareTooltip: !this.state.showShareTooltip
        });
    }

    render() {
        const {
            layoutType = 'default',
            pages,
            menuOpen,
            setAppState = (state: AppState) => {}
        } = this.props;

        const isTransparentMenu = layoutType !== 'default';

        return (
            <header className={ [
                headerClassName,
                isTransparentMenu ? headerTransparentClassName : '',
                menuOpen ? menuOpenedClassName : menuClosedClassName
            ].join(' ') }>
                <div className={ headerInnerClassName }>
                    { (layoutType !== 'full-header') && (
                        <div className={ brandAreaClassName } onClick={ () => setAppState({menuOpen: false}) }>
                            <Link to="/">
                                { layoutType === 'default'
                                    ? <img height="100%" alt="Work Of Peace" className={ logoDefaultClassName } src={ mainLogoBlue }/>
                                    : <img height="100%" alt="Work Of Peace" className={ logoDefaultClassName } src={ mainLogoWhite }/>
                                }
                                <img height="100%" alt="Work Of Peace" className={ logoMenuOpenClassName } src={ mainLogoWhite }/>
                            </Link>
                        </div>
                    ) }
                    <div className={ centerAreaClassName }>
                        {/* { layoutType === 'default' && (
                            <Link to="/exhibitions" className={ headerExhibitionGuideClassName }>
                                <img height="100%" alt="Exhibition Guide" src="/data/images/exhibition-guide.png"/>
                            </Link>
                        ) } */}
                    </div>
                    <nav className={ navClassName }>
                        <IconButton
                            size={ 30 }
                            svgName={
                                isTransparentMenu
                                    ? (
                                        menuOpen ? 'closeWhite' : 'menuWhite'
                                    )
                                    : (
                                        menuOpen ? 'close' : 'menu'
                                    )
                            }
                            onClick={ () => setAppState({menuOpen: !menuOpen}) }/>

                        <ul className={ menuListClassName }>
                            <li id="c" className={ headerExhibitionGuideTwoClassName } onClick={ () => setAppState({menuOpen: false}) }>
                                {/* <Link to="/exhibitions" className={ menuListOptionClassName }>
                                    <img height="100%" alt="Exhibition Guide" src="/data/images/exhibition-guide.png"/>
                                </Link> */}
                            </li>
                            { pages.map((page, index) => (
                                <li key={ index } onClick={ () => setAppState({menuOpen: false}) } 
                                >
                                    <NavLink to={ `/${page.alias}` } 
                                        className={ menuListOptionClassName }>
                                        { page.title }
                                    </NavLink>
                                </li>
                            )) }

                            <li>
                                <button className={ menuListOptionClassName } onClick={ e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.toggleShareTooltip();
                                } }>
                                    Share
                                </button>
                                { this.state.showShareTooltip && (
                                    <ShareTooltip/>
                                ) }
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
};

const ShareTooltip: React.FC = () => {
    const sharePageUrl = 'https://workofpeace.org/';
    const shareSubject = 'The Work of Peace Exhibit - 75 Years United Nations.';
    const shareBody = 'Explore the online exhibit at: workofpeace.org';
    const shareText = `${shareSubject} ${shareBody}`;

    return (
        <div className={ shareTooltipClassName }>
            <div className={ shareTooltipTitleClassName }>
                Share
            </div>
            <ul className={ shareTooltipListClassName }>
                <li>
                    <FacebookShareButton
                        url={ sharePageUrl }
                        quote={ shareText }>
                            <Svg name="facebook" size={ 30 }/>
                            Share on Facebook
                    </FacebookShareButton>
                </li>
                <li>
                    <EmailShareButton
                        url={ sharePageUrl }
                        subject={ shareSubject }
                        body={ shareBody }>
                            <Svg name="mail" size={ 30 }/>
                            Email
                    </EmailShareButton>
                </li>
                <li>
                    <LinkedinShareButton
                        url={ sharePageUrl }
                        title={ shareSubject }
                        summary={ shareBody }>
                            <Svg name="linkedin" size={ 30 }/>
                            Share on Linkedin
                    </LinkedinShareButton>
                </li>
                <li>
                    <TwitterShareButton
                        url={ sharePageUrl }
                        title={ shareText }>
                            <Svg name="twitter" size={ 30 }/>
                            Share on Twitter
                    </TwitterShareButton>
                </li>
                <li>
                    <WhatsappShareButton
                        url={ sharePageUrl }
                        title={ shareText }>
                            <Svg name="whatsapp" size={ 30 }/>
                            Share on WhatsApp
                    </WhatsappShareButton>
                </li>
            </ul>
        </div>
    );
};

export default connect((state: any) => {
    return {
        menuOpen: state.AppReducer.menuOpen
    }
}, { setAppState })(Header);

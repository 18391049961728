import { connect } from 'react-redux';
import { AppActions, ExhibitionData, BoxData } from '../../../state/interfaces';
import { setAppState } from '../../../state/actions';

export interface ExhibitionStateProps extends AppActions {
    exhibitions?: ExhibitionData[] | null,
    modalCustomContent?: boolean,
    modalBox?: BoxData,
    scrollHandlerState: boolean
}

const mapStateToProps = (state: any) => ({
    exhibitions: state.AppReducer.exhibitions,
    modalCustomContent: state.AppReducer.modalCustomContent,
    modalBox: state.AppReducer.modalBox,
    scrollHandlerState: state.AppReducer.scrollHandlerState
});

const mapDispatchToProps = {
    setAppState
};

export const connectState = connect(mapStateToProps, mapDispatchToProps);
import { style } from 'typestyle';
import { COLOR_BACKGROUND, FONT_FAMILY_3, COLOR_PRIMARY } from '../../../style/constants';

export const quoteBoxDescriptionClassName = style({
    margin: '0 0 15px 0',
    color: COLOR_BACKGROUND,
    fontFamily: FONT_FAMILY_3,
    fontSize: '17px',
    fontStyle: 'italic',

    $nest: {
        '& > a': {
            color: '#FFFFFF',
            textDecoration: 'none'
        }
    }
});

export const quoteBoxBodyClassName = style({
    color: COLOR_BACKGROUND,
    fontFamily: FONT_FAMILY_3,
    fontSize: '28px',
    fontStyle: 'italic',
    lineHeight: '1.3',

    $nest: {
        '*': {
            color: COLOR_BACKGROUND,
            fontFamily: FONT_FAMILY_3,
            fontSize: '28px',
            fontStyle: 'italic',
            lineHeight: '1.3'
        }
    }
});

export const quoteBoxGradientClassName = style({
    position: 'absolute',
    width: '100%',
    height: '100px',
    left: 0,
    bottom: 0,
    right: 0
});

export const quoteBoxReadMoreBtnClassName = style({
    display: 'block',
    position: 'relative',
    margin: '62px auto 0',
    fontStyle: 'italic',
    fontSize: '14px',
    height: '38px',
    width: 'auto',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    boxShadow: `${COLOR_PRIMARY} 0px 0px 8px -4px`,
    outline: 'none',
    cursor: 'pointer',
    color: COLOR_BACKGROUND
});

export const quoteBoxOddThemeClassName = style({
    $debugName: 'odd',
    backgroundColor: '#365c90',
    color: COLOR_BACKGROUND
});

export const quoteBoxEvenThemeClassName = style({
    $debugName: 'even',
    backgroundColor: '#657db1',
    color: COLOR_BACKGROUND
});

export const quoteBoxDarkThemeClassName = style({
    // backgroundColor: '#193264',

    // $nest: {
    //     [`.${quoteBoxBodyClassName}`]: {
    //         fontSize: '52px',
    //         lineHeight: '58px',

    //         $nest: {
    //             '*': {
    //                 fontSize: '52px',
    //                 lineHeight: '58px'
    //             }
    //         }
    //     }
    // }
});

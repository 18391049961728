import React ,{ useRef } from 'react';
import { getExhibitions } from '../../../api';
import { Link, useHistory} from 'react-router-dom';
import { Content } from '../../content/content.component';
import connectState from './exhibitions.state';
import { ExhibitionStateProps } from './exhibitions.state';
import {
    exhibitionsClassName,
    exhibitionItemClassName,
    exhibitionItemInnerClassName,
    exhibitionItemOpenClassName,
    exhibitionItemColorClassName,
    exhibitionItemTitleClassName,
    exhibitionItemDetailsClassName,
    exhibitionItemCountClassName,
    exhibitionItemCaptionClassName,
    exhibitionItemYearsClassName,
    exhibitionItemActionClassName,
    exhibitionScrollArrow,
    exhibitionLeft,
    exhibitionRight,
    exhibitionItemSvgClassName
} from './exhibitions.style';
import { zeroPad } from '../../../utils';

interface ExhibitionsProps extends ExhibitionStateProps {
}

interface ExhibitionsScrollState {
    right: boolean,
    left: boolean
}

const ExhibitionItem = ({
    title, count, caption, years, image, open, displayDelay, alias, onClick
}: {
    title: string,
    count: string,
    caption: string,
    years?: string | null,
    image?: string | null,
    open: boolean,
    displayDelay?: number,
    alias: string,
    onClick(): void
}) => {
    const history = useHistory();
    const url = `/exhibitions/${alias}`;

    return (
        <div
        onMouseEnter={() => open=true}
        onMouseLeave={() => open=false}
        
        onClick={ e => {
            e.preventDefault();
            onClick();

            if (open) {
                history.push(url);
            }
            else {
                onClick();
            }
        } }
        className={ [
            exhibitionItemClassName,
            
            image ? '' : exhibitionItemColorClassName
        ].join(' ') }
        style={ {
            backgroundImage: image ? `url(${image})` : '',
            animationDelay: `${displayDelay}ms`,
            cursor: open ? 'pointer' : 'default'
        } }>

            <div className={ exhibitionItemInnerClassName }>
                <div
                    className={ exhibitionItemTitleClassName }
                    dangerouslySetInnerHTML={ {__html: title || ''} }/>
                    
                <div className={ exhibitionItemDetailsClassName }>
                    <div className={ exhibitionItemCountClassName }>
                        { count ? zeroPad(Number(count), 2) : '' } 
                    </div>
                    <div className={ exhibitionItemCaptionClassName }>
                        { caption } 
                    </div>
                    { years && (
                        <div className={ exhibitionItemYearsClassName }>
                            { years }
                        </div>
                    ) }
                </div>
                <div className={ exhibitionItemActionClassName }>
                    <Link to={ url }>
                        <img className={ exhibitionItemSvgClassName } alt={ `Enter exhibition: ${caption}` } src="/data/images/arrow-right.png"/>
                    </Link>
                </div>
            </div>
        </div>
    )
};

class Exhibitions extends React.Component<ExhibitionsProps , ExhibitionsScrollState> {
    
    divRef = React.createRef<HTMLDivElement>();
    public state: ExhibitionsScrollState = {
        right: true,
        left: true
    }
    interval:any;
    

    componentDidMount() {
        this.setLayoutType();

    }

    setLayoutType() {
        this.props.setAppState({
            layoutType: 'full-content'
        });
    }

    setData() {
        const {
            exhibitions,
            loadingExhibitions,
            setAppState
        } = this.props;

        if ((!exhibitions || !exhibitions.length) && !loadingExhibitions) {
            setAppState({
                loadingExhibitions: true
            });

            getExhibitions().then(
                (rawExhibitions: any) => {
                    setTimeout(() => {
                        setAppState({
                            loadingExhibitions: false,
                            exhibitions: rawExhibitions
                        });
                    }, 0);
                }
            );
        }
    }

    
    horizontalScroll( name:string, flag:boolean){
        const node = this.divRef.current;
        
        if(node){
            if(name == 'right'){
                this.setState({right:flag});
               
                if(this.state.right){
                   this.interval = setInterval(() => {
                        node.scrollBy({
                            top: 0,
                            left: +200,
                            behavior: 'smooth'
                        })
                    }, 100);
                    return () => clearInterval(this.interval);
                }else{
                    clearInterval(this.interval);
                }
            }else{
                this.setState({left:flag});
                if(this.state.left){
                    this.interval = setInterval(() => {
                        node.scrollBy({
                            top: 0,
                            left: -200,
                            behavior: 'smooth'
                        })
                      }, 100);
                    return () => clearInterval(this.interval);
                }else{
                    
                    clearInterval(this.interval);
                    
                }
            }
            
        }
       
    }

    render() {
        const {
            loadingExhibitions,
            exhibitions,
            focusedExhibitionOrder,
            setAppState
        } = this.props;

        return (
            <Content full>
                {
                    loadingExhibitions && (!exhibitions || !exhibitions.length) && (
                        'Loading for first time or 0 results'
                    )
                }
                {
                    loadingExhibitions && exhibitions && !exhibitions.length && (
                        'Loading again'
                    )
                }
                {
                    !loadingExhibitions && exhibitions && exhibitions.length && (
                        <>
                        <div className={ `${exhibitionScrollArrow} ${exhibitionLeft}`}
                        onTouchStart={()=> this.horizontalScroll( 'left', false)}
                        onTouchEnd={()=> this.horizontalScroll( 'left', true)}
                        onMouseEnter={()=> this.horizontalScroll( 'left', false)}
                        onMouseLeave={()=> this.horizontalScroll( 'left', true)}></div>
                        <div className={ `${exhibitionScrollArrow} ${exhibitionRight}`}
                        onTouchStart={()=> this.horizontalScroll( 'right', false)}
                        onTouchEnd={()=> this.horizontalScroll( 'right', true)}
                        onMouseEnter={()=>  this.horizontalScroll( 'right', false) }
                        onMouseLeave={()=>  this.horizontalScroll( 'right', true) } 
                       ></div>

                        <div className={ exhibitionsClassName } ref={this.divRef} >
                            
                            { exhibitions.map((exhibition, index) =>
                                <ExhibitionItem
                                    key={ index }
                                    title={ exhibition.title }
                                    count={ String(exhibition.order) }
                                    caption={ exhibition.caption }
                                    years={ exhibition.years }
                                    image={ exhibition.image_url }
                                    open={ focusedExhibitionOrder === exhibition.order }
                                    alias={ exhibition.alias }
                                    displayDelay={ (index + 1) * 50 }
                                    onClick={ () => setAppState({
                                        focusedExhibitionOrder: exhibition.order
                                    }) }/>
                            ) }
                        </div>
                        </>
                    )
                }
            </Content>
        );
    }
}

export default connectState(Exhibitions);

import { style , keyframes, media} from 'typestyle';
import { FONT_FAMILY_2, FONT_WEIGHT_LIGHT, FONT_FAMILY_4, COLOR_BACKGROUND,
    MEDIA_LG, MEDIA_MD, MEDIA_SM,MEDIA_MD_MAX, MEDIA_XL, MEDIA_XS_ONLY  } from '../../../style/constants';

export const exhibitionOrderClassName = style({
    fontFamily: FONT_FAMILY_2,
    fontSize: '30px',
    fontWeight: FONT_WEIGHT_LIGHT
});

export const exhibitionBodyClassName = style({
    fontSize: '23px',
    fontFamily: FONT_FAMILY_4,
    lineHeight: '1.4'

    // $nest: {
    //     'strong': {
    //         fontSize: '23px',
    //         lineHeight: '28px',
    //     }
    // }
});

export const exhibitionBoxModalClassName = style({
    position: 'fixed',
    backgroundColor: COLOR_BACKGROUND,
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1100
});

export const exhibitionBoxModalColsClassName = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    position: 'relative'
});

export const exhibitionBoxModalColClassName = style({
    flexGrow: 1,
    flexShrink: 0,
    width: '50%',
    height: '100%'
});

const exhibitionItemAnimationName = keyframes({
    from: {
        opacity: 0,
        transform: 'translateY(100px)'
    },
    to: {
        opacity: 1,
        transform: 'translateY(0px)'
    }
});

export const exhibitionBoxFadeOutFromBottom = style({
    $debugName: 'exhibitionBoxFadeInClassName',
    opacity: 1,
	// animationIterationCount: 1,
	// animationTimingFunction: 'ease-in',
	// animationDuration: '2.5s'
    // opacity:1,
    animation: 'slide-in-up 1.2s',
    // transition:'all 2s ease',
    
})

export const exhibitionBoxIsVisible = style({
    $debugName: 'exhibitionsVisible',
    opacity: 1,
    transform: 'none',
    visibility: 'visible',
    
 })
export const exhibitionBoxFadeInFromBottom = style({
    $debugName: 'exhibitionsFadeIn',
    transform: 'translateY(20px)',
    transition: 'opacity 1200ms ease-out, transform 600ms ease-out,visibility 1200ms ease-out',
    willChange: 'opacity, transform, visibility',
 })
 
 

 export const exhibitionBoxPageStateOnLoad = style({
    $debugName: 'exhibitionsLoaded',
    opacity: 1,
	animationName: exhibitionItemAnimationName,
	animationIterationCount: 1,
	animationTimingFunction: 'ease-in',
	animationDuration: '1.5s'
    
 })

 export const exhibitionBoxHeight = style({
    $debugName: 'exhibitionsHeight',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100%',
    position: 'relative',
 })

export const exhibitionModalClassName = style({
    $debugName: 'exhibitionModal',
    padding: '75px 15px 75px 15px',
    maxWidth: 'minWidth: 320px',
    margin: '0 auto',
    boxSizing: 'border-box',
    minHeight: '100%',

},
   media(MEDIA_LG, {
    padding: '75px',
   }),
   media(MEDIA_XL, {
    padding: '93px',
   })
)
 







import { style, media } from 'typestyle';
import { FONT_FAMILY_1,FONT_WEIGHT_SEMIBOLD,FONT_FAMILY_2, COLOR_BACKGROUND, FONT_WEIGHT_BOLD, COLOR_PRIMARY, FONT_WEIGHT_REGULAR, MEDIA_XL } from '../../../style/constants';

export const headBoxClassName = style({
    backgroundColor: COLOR_PRIMARY
});

export const headBoxTitleClassName = style(
    {
        fontFamily: FONT_FAMILY_1,
        fontStyle: 'italic',
        color: COLOR_BACKGROUND,
        fontWeight: FONT_WEIGHT_REGULAR,
        margin: 0,
        padding: 0,
        fontSize: '44px',
        lineHeight: 1,

        $nest: {
            '& *': {
                fontFamily: FONT_FAMILY_1,
                fontStyle: 'italic',
                color: COLOR_BACKGROUND,
                fontSize: '62px',
                lineHeight: 1,
                fontWeight: FONT_WEIGHT_REGULAR,
            },
            '& p': {
                fontSize: '27px',
                lineHeight: 1
            }
        }
    },
    media(MEDIA_XL, {
        fontSize: '62px',
        lineHeight: 1,
        margin: '60px 0 30px 0'
    })
);

export const headBoxDetailClassName = style({
    fontFamily: FONT_FAMILY_2,
    fontStyle: 'normal',
    color: COLOR_BACKGROUND,
    fontSize: '20px',
    fontWeight: FONT_WEIGHT_SEMIBOLD
});

import { style , media} from 'typestyle';
import { FONT_WEIGHT_BOLD, 
    FONT_WEIGHT_EXTRABOLD, 
    FONT_WEIGHT_LIGHT, 
    MEDIA_L,MEDIA_XS_ONLY,
    FONT_WEIGHT_REGULAR, FONT_WEIGHT_SEMIBOLD,MEDIA_LG, MEDIA_MD_MAX, MEDIA_L_MAX, MEDIA_MD } from '../../style/constants';

const backgroundColor: string = '#001a52';
const sliderHeight: number = 100;
const sliderLineHeight: number = 20;

export const chartClassName = style({
    $debugName: 'chartClassName',

    border: 'solid 4px #ffb948',
    padding: '20px',
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor
});

export const chartInnerClassName = style(
    {}
);

export const chartTitleClassName = style(
    {
        $debugName: 'chartTitleClassName',
        color: '#3ea5db',
        textTransform: 'uppercase',
        fontSize: '38px',
        fontWeight: FONT_WEIGHT_EXTRABOLD,
        lineHeight: '38px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
);

export const chartSubtitleClassName = style(
    {
        $debugName: 'chartSubtitleClassName',
        textTransform: 'none',
        color: '#3ea5db',
        fontSize: '20px',
        lineHeight: '20px',
        fontWeight: FONT_WEIGHT_REGULAR,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
);

export const chartCountriesClassName = style(
    {
        $debugName: 'chartCountriesClassName',

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTop: 'solid 1px #ffffff',
        borderBottom: 'solid 1px #ffffff',
        boxSizing: 'border-box',
        padding: '30px 0',
        margin: '15px 0'
    },
    media(MEDIA_L_MAX, {
        padding: '10px 0',
    })
);

export const chartGraphicClassName = style(
    {
        $debugName: 'chartGraphicClassName',

        flexGrow: 0,
        flexShrink: 0,
        width: '30%'
    }
);

export const chartTablePercentageClassName = style({
});

export const chartTableWrapperClassName = style(
    {
        $debugName: 'chartTableWrapperClassName',

        flexGrow: 0,
        flexShrink: 0,
        width: '70%',
        boxSizing: 'border-box',
        padding: '0 0 0 20px',
        position: 'relative',

        $nest: {
            '& > table': {
                tableLayout: 'fixed',
                width: '100%',
                height: '100%',

                $nest: {
                    '& > tbody': {
                        $nest: {
                            '& > tr': {
                                $nest: {
                                    '& > td': {
                                        color: '#ffffff',
                                        height: '36px',
                                        fontSize: '24px',
                                        fontWeight: FONT_WEIGHT_BOLD,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        $nest: {
                                            [`&.${chartTablePercentageClassName}`]: {
                                                fontWeight: FONT_WEIGHT_REGULAR,
                                                fontSize: '16px'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    media(MEDIA_L_MAX, {
        maxHeight: '109px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        $nest: {
            '& > table': {
                tableLayout: 'auto',
                $nest: {
                    '& > tbody > tr': {
                        $nest: {
                            '& > td': {
                                fontSize: '12px',
                                width: '90px',
                                maxWidth: '60px',
                                padding: 0
                            }
                        }
                    }
                }
            }
        }
    })
);

export const chartSummaryClassName = style(
    {
        $debugName: 'chartSummaryClassName',
        maxHeight:'60px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        textTransform: 'uppercase'
    },media(MEDIA_MD_MAX, {
        maxHeight:'23px'
    }),
);

export const chartSummaryTitleClassName = style(
    {
        $debugName: 'chartSummaryTitleClassName',
        color: '#ffffff',
        flexGrow: 0,
        flexShrink: 1,
        fontWeight: FONT_WEIGHT_SEMIBOLD,
        fontSize: '34px',
        lineHeight: '60px'
    },
    media(MEDIA_L_MAX, {
        fontSize: '12px',
        lineHeight: '24px'
    })
);

export const chartSummaryNumberClassName = style(
    {
        $debugName: 'chartSummaryNumberClassName',
        width:'60px',
        color: '#ffffff',
        flexGrow: 1,
        flexShrink: 0,
        fontWeight: FONT_WEIGHT_EXTRABOLD,
        fontSize: '60px',
        lineHeight: '60px',
        padding: '0 20px',
        textAlign: 'center',

        $nest: {
            '& > img': {
                margin: '0 30px'
            }
        }
    },
    media(MEDIA_L_MAX, {
        fontSize: '30px',
        lineHeight: '24px'
    })
);

export const chartSummaryYearClassName = style(
    {
        $debugName: 'chartSummaryYearClassName',
        color: '#3ea5db',
        flexGrow: 0,
        flexShrink: 0,
        fontWeight: FONT_WEIGHT_EXTRABOLD,
        fontSize: '60px',
        lineHeight: '60px'
    },
    media(MEDIA_L_MAX, {
        fontSize: '30px',
        lineHeight: '24px'
    })
    
);


export const chartSliderClassName = style({
    $debugName: 'chartSliderClassName',

    height: `${sliderHeight + 40}px`,
    padding: `${((sliderHeight - sliderLineHeight) / 2) + 20}px 0`,
    margin: '15px 0',
    boxSizing: 'border-box',
    borderTop: 'solid 1px #ffffff',
    borderBottom: 'solid 1px #ffffff'
},
media(MEDIA_L_MAX, {
    padding: '30px 0px'
})
);

export const chartSliderLineClassName = style({
    $debugName: 'chartSliderLineClassName',
    height: `${sliderLineHeight}px`,
    background: 'linear-gradient(90deg, #003264 40%, #3ea4db 100%)',
    position: 'relative',

    $nest: {
        '& > span:nth-child(1)': {
            display: 'block',
            width: 0,
            height: 0,
            borderRight: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            borderTop: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            borderLeft: `${sliderLineHeight / 4}px solid transparent`,
            borderBottom: `${sliderLineHeight / 4}px solid transparent`,
            position: 'absolute',
            right: 0,
            top: 0
        },
        '& > span:nth-child(2)': {
            display: 'block',
            width: 0,
            height: 0,
            borderRight: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            borderTop: `${sliderLineHeight / 4}px solid transparent`,
            borderLeft: `${sliderLineHeight / 4}px solid transparent`,
            borderBottom: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            position: 'absolute',
            right: 0,
            bottom: 0
        },
        '& > span:nth-child(3)': {
            display: 'block',
            width: 0,
            height: 0,
            borderRight: `${sliderLineHeight / 4}px solid transparent`,
            borderTop: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            borderLeft: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            borderBottom: `${sliderLineHeight / 4}px solid transparent`,
            position: 'absolute',
            left: 0,
            top: 0
        },
        '& > span:nth-child(4)': {
            display: 'red',
            width: 0,
            height: 0,
            borderRight: `${sliderLineHeight / 4}px solid transparent`,
            borderTop: `${sliderLineHeight / 4}px solid transparent`,
            borderLeft: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            borderBottom: `${sliderLineHeight / 4}px solid ${backgroundColor}`,
            position: 'absolute',
            left: 0,
            bottom: 0
        }
    }
});

export const chartSliderTrackClassName = style({
    $debugName: 'chartSliderTrackClassName',

    position: 'relative',
    height: `${sliderLineHeight}px`,
    backgroundColor: 'transparent',
    width: `calc(100% - ${sliderHeight}px)`,
    margin: '0 auto'
});

export const chartSliderHandlerClassName = style({
    $debugName: 'chartSliderHandlerClassName',

    position: 'absolute',
    width: `${sliderHeight}px`,
    height: `${sliderHeight}px`,
    borderRadius: '50%',
    left: 0,
    marginLeft: `-${sliderHeight / 2}px`,
    top: `-${(sliderHeight / 2) - (sliderLineHeight / 2)}px`,
    backgroundColor: '#ffffff',
    cursor: 'move',
    boxShadow: 'black 0 0 8px',
    zIndex: 100
},
media(MEDIA_MD_MAX, {
    width: '50px',
    height: '50px',
    top: '-18px',
})
);

const sliderHandlerIconMargin: number = 20;
export const chartSliderHandlerIconClassName = style(
    {
        display: 'block',
        height: `${sliderHeight - (sliderHandlerIconMargin * 2)}px`,
        margin: `${sliderHandlerIconMargin}px 0 0 22px`,
    },
    media(MEDIA_MD_MAX, {
        margin: '10px 0 0 10px',
        height: '30px',
    })
);

export const chartSliderFootClassName = style(
    {
        color: '#3ea5db',
        width: '100%',
        position: 'absolute',
        top: '100%',
        margin: '10px 0 0 0',
        height: '30px',
        display: 'flex',
        fontSize: '20px',

        $nest: {
            '& > div:nth-child(1)': {
                flexGrow: 0,
                flexShrink: 0
            },
            '& > div:nth-child(2)': {
                flexGrow: 1,
                flexShrink: 0,
                textAlign: 'center'
            },
            '& > div:nth-child(3)': {
                flexGrow: 0,
                flexShrink: 0
            }
        }
    }
    
);

export const chartFooterClassName = style(
    {
        $debugName: 'chartFooterClassName',
        display: 'none',
        color: '#ffb948',
        textAlign: 'center',
        fontSize: '36px',
        fontWeight: FONT_WEIGHT_EXTRABOLD,
        lineHeight: '36px',
        margin: '20px 0'
    },
    media(MEDIA_L_MAX, {
        display: 'none',
    })
);

export const chartBarClassName = style(
    {
        position: 'relative',
        width: '100%',
        minWidth: '80px',
        backgroundColor: 'transparent',
        height: '30px'
    }
);

export const chartBarFillClassName = style(
    {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        transition: '300ms'
    }
);
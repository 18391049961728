import jsonData from './interactive-veto-chart.json';

export interface VetoeCountry {
    countryIndex: number,
    vetoes: number
}

export interface VetoeYear {
    year: number,
    countries: VetoeCountry[]
}

export interface FilteredCountryData {
    name: string,
    total: number,
    percentage: number
}

export interface FilteredData {
    totalVetoes: number,
    fromYear: number,
    toYear: number,
    selectedYear: number,
    countries: FilteredCountryData[]
}

export const COUNTRIES: string[] = [
    'China', 'France', 'USSR', 'United Kingdom', 'United States',
];

export const COUNTRY_COLORS: string[] = [
    '#7fe1d4', '#0aa891', '#8bafb5', '#095e71', '#2ec8d7'
];

export const getFilteredData = (maxIndex: number): FilteredData => {
    const result: FilteredData = {
        totalVetoes: 0,
        fromYear: VETOE_DATA[0].year,
        toYear: VETOE_DATA[VETOE_DATA.length - 1].year,
        selectedYear: VETOE_DATA[maxIndex].year,
        countries: []
    };

    const countriesInfo: any = {};

    for (let i = 0; i <= maxIndex; i++) {
        const currentVetoeData = VETOE_DATA[i];

        currentVetoeData.countries.forEach((countryData) => {
            const currentCountryName = COUNTRIES[countryData.countryIndex];

            if (!countriesInfo[currentCountryName]) {
                countriesInfo[currentCountryName] = 0;
            }

            countriesInfo[currentCountryName] = countriesInfo[currentCountryName] + countryData.vetoes;
            result.totalVetoes = result.totalVetoes + countryData.vetoes;
        });
    }

    for (const countryName in countriesInfo) {
        result.countries.push({
            name: countryName,
            total: countriesInfo[countryName],
            percentage: Math.round((countriesInfo[countryName] * 100) / result.totalVetoes)
        });
    }

    return result;
};

export const VETOE_DATA: VetoeYear[] = jsonData.map(data => ({
    year: Number(data.year),
    countries: data.countries.map(country => ({
        countryIndex: Number(country.countryIndex),
        vetoes: Number(country.vetoes)
    }))
}));

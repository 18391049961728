import React from 'react';
import { svgClassName , svgClassImg} from './svg.styles';
import svgIconPlay from '../../svg/icon-play.svg';
import svgArrowRight from '../../svg/icon-arrow-right.svg';
import svgExternalLink from '../../svg/icon-external-link.svg';
import svgIconShare from '../../svg/icon-share.svg';
import svgIconClose from '../../svg/icon-close.svg';
import svgIconNext from '../../svg/icon-next.svg';
import svgIconPrevious from '../../svg/icon-previous.svg';
import svgIconMenu from '../../svg/icon-menu.svg';
import svgIconMenuWhite from '../../svg/icon-menu-white.svg';
import svgIconCloseWhite from '../../svg/icon-close-white.svg';
import svgIconDocument from '../../svg/icon-document.svg';
import svgIconWebsite from '../../svg/icon-website.svg';

import svgIconFullscreen from '../../svg/icon-fullscreen.svg';

import svgIconFacebook from '../../svg/icon-facebook.svg';
import svgIconMail from '../../svg/icon-mail.svg';
import svgIconLinkedin from '../../svg/icon-linkedin.svg';
import svgIconTwitter from '../../svg/icon-twitter.svg';
import svgIconWhatsApp from '../../svg/icon-whatsapp.svg';

export type SvgName = 'play' | 'portraitPlay' | 'arrowRight' | 'externalLink' | 'share' | 'close' | 'next'
    | 'previous' | 'menu' | 'facebook' | 'mail' | 'linkedin' | 'twitter' | 'whatsapp'
    | 'menuWhite' | 'closeWhite' | 'websiteLink' | 'documentLink' | 'fullscreen';

interface SVGProps {
    alt?: string,
    size?: number,
    name: SvgName,
    gallerySubIcons?: boolean,
}

const svgs = {
    'play': svgIconPlay,
    'portraitPlay':svgIconPlay,
    'arrowRight': svgArrowRight,
    'externalLink': svgExternalLink,
    'playLink': svgIconPlay,
    'websiteLink': svgIconWebsite,
    'documentLink': svgIconDocument,
    'share': svgIconShare,
    'close': svgIconClose,
    'next': svgIconNext,
    'previous': svgIconPrevious,
    'menu': svgIconMenu,
    'facebook': svgIconFacebook,
    'mail': svgIconMail,
    'linkedin': svgIconLinkedin,
    'twitter': svgIconTwitter,
    'whatsapp': svgIconWhatsApp,
    'menuWhite': svgIconMenuWhite,
    'closeWhite': svgIconCloseWhite,
    'fullscreen': svgIconFullscreen
};

export const Svg: React.FC<SVGProps> = ({
    size = 50,
    name,
    alt = name,
    gallerySubIcons=false
}) => (
    name === 'menu'
    || name === 'close'
    || name === 'closeWhite'
    || name === 'menuWhite'
    || name === 'previous'
    || name === 'next'
    || name === 'facebook'
    || name === 'mail'
    || name === 'linkedin'
    || name === 'twitter'
    || name === 'whatsapp'
    || name === 'portraitPlay'
    || name === 'fullscreen'
    ?
    <img
        className={`${svgClassName}` }
        width={ `${size}px` }
        height={ `${size}px` }
        src={ svgs[name] }
        alt={ alt }/>
    : (
        <img
        className={ `${svgClassName} ${svgClassImg}`  }
        width={ `${size}px` }
        height={ `${size}px` }
        src={ svgs[name] }
        alt={ alt }/>
    )
   
);

import  React from 'react';
import { Box, BoxCaption } from '../box.component';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { AppActions } from '../../../state/interfaces';

interface WebsiteBoxProps extends AppActions {
    box: BoxData
}

const WebsiteBox: React.FC<WebsiteBoxProps> = ({
    box,
    setAppState
}) => (
    <Box bgImage={box.image_url} externalLink ={box.external_link} onClick={ () => setAppState({
        modalCustomContent: false,
        modalBox: null
    })}>
        <BoxCaption
            name="Website:"
            title={ box.title}
            websiteBtn = {box.external_link}
        />
    </Box>
);

export default connectAppActions()(WebsiteBox);
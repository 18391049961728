import React, { useRef, useEffect, useState, ReactNode} from 'react';
import { Box } from '../box.component';
import { headBoxTitleClassName, headBoxDetailClassName, headBoxClassName } from './head-box.styles';
import { useHistory } from "react-router-dom";

interface HeadBoxProps {
    title: string,
    detail?: string | null,
    image?: string | null,
    id?:string | null,
}

export const HeadBox: React.FC<HeadBoxProps> = ({
    title, detail, image, id
}) => {
    
    return(
        id
        ? (
            
        <Box className={ headBoxClassName } bgImage={ image } id={id}>
                { title && (
                    <div id={id}
                        className={ headBoxTitleClassName
                            }
                        dangerouslySetInnerHTML={ {__html: title} }/>
                ) }
                { detail && (
                    <div className={ headBoxDetailClassName }>{ detail }</div>
                ) }
        </Box>
        )
        : (
            <Box className={ headBoxClassName } bgImage={ image }>
                { title && (
                    <div 
                        className={ headBoxTitleClassName }
                        dangerouslySetInnerHTML={ {__html: title} }/>
                ) }
                { detail && (
                    <div className={ headBoxDetailClassName }>{ detail }</div>
                ) }
            </Box>
        )
        
    
    )

}

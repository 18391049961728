import React from 'react';
import { Link } from 'react-router-dom';
import {
    homeClassName,
    middleHomeClassName,
    bottomHomeClassName,
    bottomLeftHomeClassName,
    bottomRightHomeClassName,
    enterExhibitionBtnClassName,
    bottomLeftHomeDPAAClassName,
    diplomacy4PeaceBigLogoClassName
} from './home.styles';
import { exhibitionItemSvgClassName } from '../exhibitions/exhibitions.style';
import { Content } from '../../content/content.component';
import { connectState, HomeStateProps } from './home.state';
import diplomacy4PeaceLogo from '../../../images/Diplomacy4Peace-Logo-4x.png';
import diplomacy4PeaceHomeBG from '../../../images/Diplomacy4Peace-BG-3x.jpg';

interface HomeProps extends HomeStateProps {
}

class Home extends React.Component<HomeProps> {
    componentDidMount() {
        this.props.setAppState({
            layoutType: 'full-header'
        });
    }

    render() {
        return (
            <Content full backgroundImage={ diplomacy4PeaceHomeBG }>
                <div className={ homeClassName }>
                    <div className={ middleHomeClassName }>
                        <img className={ diplomacy4PeaceBigLogoClassName } src={ diplomacy4PeaceLogo } alt="Diplomacy 4 Peace" />
                    </div>
                    <div className={ bottomHomeClassName }>
                        <div className={ bottomLeftHomeClassName }>
                            <a href={"https://dppa.un.org/"}><img className={ bottomLeftHomeDPAAClassName } alt="DPAA Logo" src="/data/images/dppa-logo.png"/></a>
                        </div>
                        <div className={ bottomRightHomeClassName }>
                            <Link to="/exhibitions" className={ enterExhibitionBtnClassName }>
                                enter
                                <img alt="Enter exhibit" className={ exhibitionItemSvgClassName } src="/data/images/arrow-right.png"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
}

export default connectState(Home);

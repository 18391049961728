import { connect } from 'react-redux';
import { setAppState } from '../../state/actions';


// document.addEventListener('scroll', (ev)=>{})
export const connectAppActions = () => connect(null, {setAppState});
//export const listen

const blueColorList = ['#193264', '#375a90', '#657db1'];
let currentColorIndex = -1;
export const getColorBlue = (): string => {
    currentColorIndex = currentColorIndex + 1;

    if (currentColorIndex >= blueColorList.length) {
        currentColorIndex = 0;
    }

    return blueColorList[currentColorIndex];
};

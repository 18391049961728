import React from 'react';
import { Box } from '../box.component';
import { captionBoxInnerClassName, captionBoxClassName } from './caption-box.styles';

interface CaptionBoxProps {
    text?: string,
    image?: string,
    type?:string
}

export const CaptionBox: React.FC<CaptionBoxProps> = ({
    text,
    image,
    type
}) => (
    <Box bgImage={ image } className={ captionBoxClassName }>
        <div className={ captionBoxInnerClassName }>
            { text }
        </div>
    </Box>
);
import { style } from 'typestyle';
import { HEADER_HEIGHT } from '../../style/constants';

export const contentClassName = style({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    minHeight: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxSizing: 'border-box',
    padding: `${HEADER_HEIGHT}px 0 0 0`
});

export const contentFullClassName = style({
    padding: 0
});

export const contentInnerClassName = style({
    display: 'block',
    position: 'relative',
    minHeight: '100%',
    flexGrow: 1,
    flexShrink: 0
});

export const holis = style({
    padding: 0
});


   

    
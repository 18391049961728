export const getAll = () => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch('/data/json/data.json');
        const data = await response.json();

        resolve(data);
    }
    catch(err) {
        reject(err);
    }
});

export const getExhibitions = () => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch('/data/json/exhibitions.json');
        const exhibitions = await response.json();

        resolve(exhibitions);
    }
    catch(err) {
        reject(err);
    }
});

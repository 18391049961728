import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

const titleBoxInnerCSS: NestedCSSProperties = {
    fontSize: '34px',
    fontStyle: 'italic',
    color: 'white',
    padding: '0 20px 0 0'
};

export const titleBoxInnerClassName = style(titleBoxInnerCSS);
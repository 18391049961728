import { media, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { COLOR_PRIMARY,FONT_FAMILY_4, COLOR_BACKGROUND, COLOR_TEXT_PRIMARY, MEDIA_LG, MEDIA_XL } from '../../../style/constants';
import Color from 'color';

const TEXT_BOX_BG_COLOR = Color(COLOR_PRIMARY).lighten(4.7).hex();

export const getGradient = (color: string): string =>
    `linear-gradient(0deg, ${color} 40%, ${Color(color).fade(1)} 100%)`;


// Base style

const textBoxInnerCSS: NestedCSSProperties = {
    height: '100%',
    overflow: 'hidden'
};

const textBoxButtonPadCSS: NestedCSSProperties = {
    $debugName: 'NestedCSSProperties',
    position: 'absolute',
    bottom: '-1px',
    left: 0,
    right: 0,
    height: '38px',
    overflow: 'visible',
    boxSizing: 'content-box',
    background: `linear-gradient(0deg, ${TEXT_BOX_BG_COLOR} 40%, ${Color(TEXT_BOX_BG_COLOR).fade(1)} 100%)`,
    padding: '100px 0 0 0'
};

const textBoxButtonCSS: NestedCSSProperties = {
    display: 'block',
    position: 'relative',
    margin: '0 auto',
    fontStyle: 'italic',
    fontSize: '14px',
    height: '38px',
    width: 'auto',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    boxShadow: `${COLOR_PRIMARY} 0px 0px 8px -4px`,
    outline: 'none',
    cursor: 'pointer',
    padding: '10px 15px'
};

// Export

export const textBoxClassName = style(
    {
        $debugName: 'textBoxClassName',

        backgroundColor: TEXT_BOX_BG_COLOR,
        color: COLOR_TEXT_PRIMARY,

        $nest: {
            '& > div': {
                maxWidth: '520px',
                margin: '0 auto',
                $nest:{
                    'p':{
                        fontFamily: FONT_FAMILY_4,
                        $nest:{
                            'strong':{
                                fontSize: '24px',
                            }
                        }
                    }
                }
            }
        }
    },
    media(MEDIA_LG, {
        $nest: {
            '& > div': {
                margin: '45px',
                height: 'calc(100% - 90px)'
            }
        }
    }),
    media(MEDIA_XL, {
        $nest: {
            '& > div': {
                margin: '63px',
                height: 'calc(100% - 125px)'
            }
        }
    })
);
export const textBoxInnerClassName = style(textBoxInnerCSS);
export const textBoxContentClassName = style({
    maxHeight: '100%',
    overflow: 'hidden',
});
export const textBoxButtonPadClassName = style(textBoxButtonPadCSS);
export const textBoxButtonClassName = style({
    display: 'block',
    position: 'relative',
    margin: '0 auto',
    fontStyle: 'italic',
    fontSize: '14px',
    height: '38px',
    width: 'auto',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    boxShadow: `${COLOR_PRIMARY} 0px 0px 8px -4px`,
    outline: 'none',
    cursor: 'pointer',
    padding: '10px 15px',
    $nest:{
        '&:hover': {
            opacity:0.5
        }
}});

export const textBoxTypeTitleClassName = style({
    backgroundColor: COLOR_BACKGROUND,

    $nest: {
        [`.${textBoxButtonPadClassName}`]: {
            background: getGradient(COLOR_BACKGROUND)
        },
        '.page-title':{
            fontStyle: 'normal',
        }
    }
});

export const textBoxTypeBodyClassName = style({
    backgroundColor: TEXT_BOX_BG_COLOR,

    $nest: {
        [`.${textBoxButtonPadClassName}`]: {
            background: getGradient(TEXT_BOX_BG_COLOR)
        }
    }
});

export const textBoxTypeBodyContrastClassName = style({
    backgroundColor: '#365c90',


    $nest: {
        [`.${textBoxButtonPadClassName}`]: {
            background: getGradient('#365c90'),

            $nest: {
                [`.${textBoxButtonClassName}`]: {
                    color: COLOR_BACKGROUND
                }
            }

        },
        [`.${textBoxContentClassName}`]: {
            color: COLOR_BACKGROUND,

            $nest: {
                'p': {color: COLOR_BACKGROUND},
                'a': {color: COLOR_BACKGROUND},
                'h1': {color: COLOR_BACKGROUND},
                'h2': {color: COLOR_BACKGROUND},
                'h3': {color: COLOR_BACKGROUND},
                'h4': {color: COLOR_BACKGROUND}
            }
        }
    }
});

import React  from 'react';
import { MetroBlockData } from '../../state/interfaces';
import { MetroBlock } from '../metro/metro-block.component';
import { BoxData } from '../../state/interfaces';
import { Box, BoxCaption } from '../boxes/box.component';
import {
    exhibitionBodyClassName,
    exhibitionBoxFadeInFromBottom,
    exhibitionBoxIsVisible,
} from '../pages/exhibition/exhibition.styles';
import {
    BodyBox,
    VideoBox,
    ImageBox,
    PortraitBox,
    QuoteBox,
    TextBox,
    CaptionBox, 
    WebsiteBox,
    DocumentBox,
    TitleBox
} from '../boxes';
import { Link } from 'react-router-dom';
import { EventBox } from '../boxes/event-box/event-box.component';
import { InteractiveVetoChart } from '../interactive-veto-chart/interactive-veto-chart.component';
import ImageSlideshowBox from '../boxes/image-slideshow-box/image-slideshow-box.component';

interface MetroContentProps {
    metroBlock: MetroBlockData,
    parentPath?: string,
    action?(): void,
    id?: string,
    lastIndexRef?:string,
    boxAlias?:boolean,
    firstLoaded?:boolean,
}

export const renderBox = (boxData: BoxData, parentPath?: string, lastIndexRef?:string) => {
    switch (boxData.type) {
        case 'video':
            return (
                boxData.video_url &&
                <VideoBox  box={ boxData } linkTo={ `${parentPath}/${boxData.alias}` }/>
            );

        case 'image':
            return (
                boxData.image_url &&
                <ImageBox box={ boxData }  linkTo={ `${parentPath}/${boxData.alias}` }/>
            );

        case 'image_link':
            return (
                boxData.image_url && boxData.external_link && 
                <ImageBox box={ boxData } onClick={ () => window.open(boxData.external_link, '_blank') }/>
            );

        case 'image_large':
            return (
                boxData.image_url &&
                (
                    <img src={boxData.image_url} alt={boxData.image_alt || "Photo"} width={"100%"} />
                )
                
            );

        case 'image-slideshow':
            return (
                boxData.image_list && (
                    <ImageSlideshowBox box={ boxData } linkTo={ `${parentPath}/${boxData.alias}` }/>
                )
            );

        case 'portrait':
            return (
                boxData.title && boxData.image_url &&
                <PortraitBox box={ boxData } linkTo={ `${parentPath}/${boxData.alias}` }/>
            );

        case 'quote':
            return (
                <QuoteBox
                    linkTo={ `${parentPath}/${boxData.alias}` }
                    box={ boxData }
                    description={ boxData.description }
                    body={ boxData.body }
                    externalLink={ boxData.external_link }
                    shareBtn={ boxData.share }
                    image={ boxData.image_url }
                    />
            );

        case 'body':
            return (
                <BodyBox box={ boxData } type={ boxData.contrast ? 'body-contrast' : 'body' } linkTo={ `${parentPath}/${boxData.alias}` }/>
            );

        case 'person':
            return (
                <Box bgImage={ boxData.image_url }>
                    <BoxCaption 
                        type={'person'}
                        title={ boxData.title }
                        description={ boxData.description }/>
                </Box>
            );

        case 'text':
            return (
                <TextBox box={ boxData } linkTo={ `${parentPath}/${boxData.alias}` }/>
            );

        case 'caption':
            return (
                <CaptionBox text={ boxData.title } image={ boxData.image_url }/>
            );

        case 'event':
            return (
                <EventBox box={ boxData } type={ 'body' } linkTo={ `${parentPath}/${boxData.alias}` }/>
            );

        case 'interactive_slider':
            return (
                <InteractiveVetoChart/>
            );

        case 'website':
            return (
                <WebsiteBox box={ boxData} />
            );

        case 'document':
            return (
                <DocumentBox box={ boxData } />
            );

        // case 'title':
        //     return (
        //         <TitleBox box={ boxData }/>
        //     )
    }
};

export const MetroContent: React.FC<MetroContentProps> = ({
    metroBlock,
    parentPath,
    id, 
    boxAlias,
    firstLoaded,
}) => {
    return(
        id ?
    (
        
        <MetroBlock size={ metroBlock.size } firstLoaded={firstLoaded} boxAlias={boxAlias} id={id} group={ Boolean(metroBlock.child_blocks) } leftChildBlock={Boolean(metroBlock.child_blocks_left)}>
        {
            metroBlock.box
            ? renderBox(metroBlock.box, parentPath  )
            : (
                metroBlock.child_blocks
                ? (
                    metroBlock.child_blocks.map((childMetroBlock, index) => (
                        <MetroBlock key={ index }  firstLoaded={firstLoaded} size={ childMetroBlock.size } id={id} childOf={ metroBlock.size }>
                            {
                                childMetroBlock.box
                                ? renderBox(childMetroBlock.box, parentPath)
                                : <div dangerouslySetInnerHTML={ {__html: childMetroBlock.content || ''} }/>
                            }
                        </MetroBlock>
                    ))
                )
                :metroBlock.child_blocks_left 
                ?
                (
                    metroBlock.child_blocks_left.map((childMetroBlock, index) => (
                        <MetroBlock key={ index } size={ childMetroBlock.size } id={id} childOf={ metroBlock.size } >
                            {
                                childMetroBlock.box
                                ? renderBox(childMetroBlock.box, parentPath)
                                : <div dangerouslySetInnerHTML={ {__html: childMetroBlock.content || ''} }/>
                            }
                        </MetroBlock>
                    ))
                )
                : <div dangerouslySetInnerHTML={ {__html: metroBlock.content || ''} }/>
                
            )
        }
        </MetroBlock>
    ):(
        <MetroBlock size={ metroBlock.size } group={ Boolean(metroBlock.child_blocks) }>
            {
                metroBlock.box
                ? renderBox(metroBlock.box, parentPath)
                : (
                    metroBlock.child_blocks
                    ? (
                        metroBlock.child_blocks.map((childMetroBlock, index) => (
                            <MetroBlock key={ index } size={ childMetroBlock.size } childOf={ metroBlock.size }>
                                {
                                    childMetroBlock.box
                                    ? renderBox(childMetroBlock.box, parentPath)
                                    : <div dangerouslySetInnerHTML={ {__html: childMetroBlock.content || ''} }/>
                                }
                            </MetroBlock>
                        ))
                    )
                    : <div dangerouslySetInnerHTML={ {__html: metroBlock.content || ''} }/>
                )
            }
        </MetroBlock>
    )
    )
}

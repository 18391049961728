import React from 'react';
import {
    boxPageClassName,
    boxPageColumnsWrapperClassName,
    boxPageOneColumnClassName,
    boxPageLeftColumnClassName,
    boxPageRightColumnClassName,
    boxPageColumnMediaClassName,
    boxPageColumnTextClassName,
    boxPageColumnBody,
    boxPageVideoClassName,
    boxPageButtonPadClassName,

    boxPageQuoteFormatClassName,
    boxPageTextFormatClassName,
    boxPagePortraitGalleryClassName,
    boxPagePortraitGalleryImageClassName,
    boxPagePortraitGalleryVideoClassName,
    boxPagePortraitGalleryQuoteClassName,
    boxPagePortraitGalleryItemInnerClassName,
    boxPageVideoTitleClassName,
    boxPagePortraitGalleryQuoteFormatClassName,

    boxPageQuoteTitleFormatClassName,
    boxPageQuoteBodyFormatClassName
} from './box-page.styles';
import { BoxData } from '../../state/interfaces';
import {
    exhibitionBodyClassName
} from '../pages/exhibition/exhibition.styles';
import quoteMarkSymbolImage from '../../images/QuoteMark_Symbol.png';
import { IconButton } from '../icon-button/icon-button.component';
import { COLOR_BACKGROUND, FONT_FAMILY_2, FONT_FAMILY_3, FONT_WEIGHT_BOLD } from '../../style/constants';
import { Link } from 'react-router-dom';
import { Svg } from '../svg/svg.component';
import ImageSlideshowBox from '../boxes/image-slideshow-box/image-slideshow-box.component';

interface BoxPageProps {
    boxData: BoxData,
    onNext?(): void,
    onPrev?(): void,
    nextUrl?: string,
    prevUrl?: string
}

interface BoxPagePortraitProps {
    boxData: BoxData
}

interface BoxPagePortraitState {
    videoModal: null | {
        title: string,
        url: string
    }
}

class BoxPagePortrait extends React.PureComponent<BoxPagePortraitProps, BoxPagePortraitState> {
    public state: BoxPagePortraitState = {
        videoModal: null
    };

    render() {
        const { boxData } = this.props;

        return (
            <div className={ boxPageColumnsWrapperClassName }>
                <div className={ boxPageLeftColumnClassName }>
                    <img width="100%" src={ boxData.image_url }/>
                    { boxData.gallery && (
                        <div className={ boxPagePortraitGalleryClassName }>
                            { boxData.gallery.map((galleryItem, index) => {
                                switch(galleryItem.type) {
                                    case 'image': return (
                                        <div
                                            key={ index }
                                            className={ boxPagePortraitGalleryImageClassName }
                                            style={ {
                                                backgroundImage: `url(${galleryItem.url})`
                                            } }/>
                                    );

                                    case 'video': return (
                                        this.state.videoModal
                                            ? (
                                                <div key={ index } style={ {
                                                    width: '100%',
                                                    float: 'left',
                                                    position: 'relative'
                                                } }>
                                                    <iframe
                                                        style={ {
                                                            width: '100%',
                                                            display: 'block'
                                                        } }
                                                        src={ this.state.videoModal.url }
                                                        width="1280"
                                                        height="720"
                                                        frameBorder="0"
                                                        allow="autoplay; fullscreen"
                                                        allowFullScreen/>
                                                    <div style={ {
                                                        fontFamily: FONT_FAMILY_2,
                                                        fontWeight: FONT_WEIGHT_BOLD,
                                                        color: COLOR_BACKGROUND,
                                                        backgroundColor: '#000000',
                                                        padding: '20px',
                                                        fontSize: '26px'
                                                    } }>
                                                        { this.state.videoModal.title }
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div
                                                    key={ index }
                                                    className={ boxPagePortraitGalleryVideoClassName }
                                                    style={ {
                                                        backgroundImage: `url(${galleryItem.url})`
                                                    } }
                                                    onClick={ () => this.setState({
                                                        videoModal: {
                                                            title: galleryItem.title,
                                                            url: galleryItem.video_url
                                                        }
                                                    }) }>

                                                        <div className={ boxPageVideoTitleClassName }>
                                                            <small>Video:</small>
                                                            { galleryItem.title }
                                                            <IconButton size={ 60 }  svgName="portraitPlay" onClick={ () => {} }/>
                                                        </div>
                                                </div>
                                            )

                                    );

                                    case 'quote': return (
                                        <div
                                            key={ index }
                                            className={ `${boxPagePortraitGalleryQuoteClassName}  ${boxPageQuoteFormatClassName}`}                                           style={ {
                                                backgroundColor: '#365c90',
                                                backgroundImage:`url(${quoteMarkSymbolImage})` 
                                            } }
                                            >
                                                <div
                                                    className={ boxPagePortraitGalleryQuoteFormatClassName }
                                                    dangerouslySetInnerHTML={ {__html: galleryItem.body} }/>

                                        </div>
                                    );
                                }
                            }) }
                        </div>
                    ) }
                </div>
                <div className={ boxPageRightColumnClassName }>
                    <h1 dangerouslySetInnerHTML={ {__html: boxData.title || '' } }/>
                    <div dangerouslySetInnerHTML={ {__html: boxData.body || '' } }/>
                </div>
            </div>
        );
    }
}

const renderBoxPage = (boxData: BoxData) => {
    switch(boxData.type) {
        case 'body': return (
            <div className={ boxPageColumnsWrapperClassName } style={ {backgroundColor: '#D4E2FF'} }>
                <div className={ boxPageOneColumnClassName }>
                    { boxData.title && <h2>{ boxData.title }</h2> }
                    { boxData.body && (
                        <div className={ exhibitionBodyClassName } dangerouslySetInnerHTML={ {
                            __html: boxData.body
                        } }/>
                    ) }
                </div>
            </div>
        );

        case 'event': return (
            <div className={ boxPageColumnsWrapperClassName } style={ {backgroundColor: '#D4E2FF'} }>
                <div className={ boxPageOneColumnClassName }>
                    { boxData.title && <h2>{ boxData.title }</h2> }
                    { boxData.body && (
                        <div className={ exhibitionBodyClassName } dangerouslySetInnerHTML={ {
                            __html: boxData.body
                        } }/>
                    ) }
                    
                </div>
            </div>
        );

        case 'image': return (
            <div className={ boxPageColumnsWrapperClassName }>
                <div className={ boxPageColumnMediaClassName } style={ {
                    backgroundImage: `url(${boxData.image_detail_url || boxData.image_url})`,
                    backgroundSize: `${boxData.image_detail_contain || 'cover'}`
                } }/>
                <div className={ boxPageColumnTextClassName }>
                    <h1 dangerouslySetInnerHTML={ {__html: boxData.title || '' } }/>
                    <div className={boxPageColumnBody } dangerouslySetInnerHTML={ {__html: boxData.body || '' } }/>
                </div>
            </div>
        );

        case 'video': return (
            <div className={ boxPageColumnsWrapperClassName }>
                <div className={ boxPageColumnMediaClassName } style={ {backgroundColor: '#000000'} }>
                    <iframe
                        className={ boxPageVideoClassName }
                        src={ boxData.video_url }
                        width="1920"
                        height="1080"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen/>
                </div>
                <div className={ boxPageColumnTextClassName }>
                    <h1 dangerouslySetInnerHTML={ {__html: boxData.title || '' } }/>
                    <div className={boxPageColumnBody } dangerouslySetInnerHTML={ {__html: boxData.body || '' } }/>
                </div>
            </div>
        );

        case 'portrait': return (
            <BoxPagePortrait boxData={ boxData }/>
        );

        case 'quote': return (
            <div className={ boxPageColumnsWrapperClassName }>
                <div className={ boxPageColumnMediaClassName }>
                    {/* <img src={ quoteMarkSymbolImage } alt="Quotemark"/> */}
                    <div
                        className={ boxPageQuoteFormatClassName }
                        dangerouslySetInnerHTML={ {__html: boxData.body || ''} }
                        style={{backgroundImage:`url(${quoteMarkSymbolImage})`}}/>
                </div>
                <div className={ boxPageColumnTextClassName }>
                    <h1 className={ boxPageQuoteTitleFormatClassName } dangerouslySetInnerHTML={ {__html: boxData.title || ''} }/>
                    <div className={ boxPageQuoteBodyFormatClassName } dangerouslySetInnerHTML={ {__html: boxData.description || ''} }/>
                </div>
            </div>
        );

        case 'text' : return (
            <div className={ boxPageColumnsWrapperClassName } style={ {
                backgroundColor: '#193264',
                backgroundImage: boxData.image_url ? `url(${boxData.image_url})` : 'none'
            } }>
                <div className={ boxPageOneColumnClassName }>
                    <div className={ boxPageTextFormatClassName }>
                        <h1 dangerouslySetInnerHTML={ {__html: boxData.title || ''} }/>
                        <div className={boxPageColumnBody } dangerouslySetInnerHTML={ {__html: boxData.body || ''} }/>
                    </div>

                </div>
            </div>
        );

        case 'image-slideshow': return (
            <ImageSlideshowBox box={ boxData }/>
        );

        default: return (
            <div className={ boxPageColumnsWrapperClassName }>
                <div className={ boxPageOneColumnClassName }>
                    { boxData.title && (
                        <div>
                            <div>Title</div>
                            <div className={boxPageColumnBody } dangerouslySetInnerHTML={ {__html: boxData.title} }/>
                        </div>
                    ) }

                    { boxData.description && (
                        <div>
                            <div>Description</div>
                            <div dangerouslySetInnerHTML={ {__html: boxData.description} }/>
                        </div>
                    ) }

                    { boxData.body && (
                        <div>
                            <div>Body</div>
                            <div className={boxPageColumnBody } dangerouslySetInnerHTML={ {__html: boxData.body} }/>
                        </div>
                    ) }

                    { boxData.image_url && (
                        <div>
                            <div>Image</div>
                            <img src={ boxData.image_url } alt={ boxData.image_alt }/>
                        </div>
                    ) }

                    {/* { boxData.image_large_url && (
                        <div>
                            <div>Image</div>
                            <img src={ boxData.image_large_url } alt={ boxData.image_alt }/>
                        </div>
                    ) } */}

                </div>
            </div>
        );
    }
};

const buttonSize = 100;

export const BoxPage: React.FC<BoxPageProps> = ({
    boxData
}) => {
    return(
    
    <div className={ boxPageClassName }>
        { renderBoxPage(boxData) }
        <div className={ boxPageButtonPadClassName }>
            {
                boxData.$previousBoxUrl
                ? (
                    <IconButton linkTo={ boxData.$previousBoxUrl } svgName="previous" size={ buttonSize }/>
                )
                : (
                    <span style={ {opacity: .15, cursor: 'not-allowed'} }>
                        <Svg name="previous" size={ buttonSize }/>
                    </span>

                )
            }
            {
                boxData.$nextBoxUrl
                ? (
                    <IconButton linkTo={ boxData.$nextBoxUrl } svgName="next" size={ buttonSize }/>
                )
                : (
                    <span style={ {opacity: .15, cursor: 'not-allowed'} }>
                        <Svg name="next" size={ buttonSize }/>
                    </span>
                )
            }
        </div>
    </div>
)};

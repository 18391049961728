import React from 'react';
import connectState, { PageStateProps } from './page.state';
import { Content } from '../../content/content.component';
import { PageData, BoxData , MetroBlockData} from '../../../state/interfaces';
import { MetroLayout, MetroBlock } from '../../metro';
import { HeadBox, BodyBox } from '../../boxes';
import { MetroContent } from '../../metro-content/metro-content.component';
import { Modal } from '../../modal/modal.component';
import { IconButton } from '../../icon-button/icon-button.component';
import { exhibitionBodyClassName, exhibitionModalClassName} from '../exhibition/exhibition.styles';
import { boxPageButtonPadClassName} from '../../box-pages/box-page.styles';
import { BoxPage } from '../../box-pages/box-page.component';


interface PageProps extends PageStateProps {
    match: any,
    history: any
}

interface PageState {
    readMore: boolean,
    classFade: boolean
}

class Page extends React.PureComponent<PageProps, PageState> {
    public state: PageState = {
        readMore: false,
        classFade: false,
    };


    componentDidMount() {
        this.props.setAppState({
            layoutType: 'default'
        });
    }

    getPage(alias: string): PageData | null {
        const { pages } = this.props;

        if (pages) {
            for (const page of pages) {
                if (page.alias === alias) {
                    const allBoxes: BoxData[] = [];

                    page.metro_layout?.metro_blocks.forEach(metroBlock => {
                        if (metroBlock.box) {
                            allBoxes.push(metroBlock.box);
                        }
                        
                        metroBlock.child_blocks?.forEach(childBlock => {
                            if (childBlock.box) {
                                allBoxes.push(childBlock.box);
                            }
                        });
                    });

                    allBoxes.forEach((box, index) => {
                        box.$previousBoxUrl = allBoxes[index - 1]?.alias;
                        box.$nextBoxUrl = allBoxes[index + 1]?.alias;
                    });

                    return page;
                }
            }
        }

        return null;
    }

    getBoxData(pageData: PageData): BoxData | null {
        const boxAlias = this.props.match.params.boxAlias;
        const metroBlocks = (pageData.metro_layout)?pageData.metro_layout.metro_blocks: null;

        if (boxAlias && metroBlocks) {
            for (const metroBlock of metroBlocks) {
                if (metroBlock.box) {
                    if (metroBlock.box.alias === boxAlias) {
                        return metroBlock.box;
                    }
                }
                else if (metroBlock.child_blocks) {
                    for (const childBlock of metroBlock.child_blocks) {
                        if (childBlock.box && (childBlock.box.alias === boxAlias)) {
                            return childBlock.box;
                        }
                    }
                }
            }
        }
        return null
    }

    render() {
        const page = this.getPage(this.props.match.params.alias);
        const boxData = (page) ? this.getBoxData(page): null;

        return (
           
            <Content  >
                { page && (
                    <MetroLayout>
                        <MetroBlock size={ 2 }  >
                            <HeadBox
                                title={ page.title }
                                image={ page.image_url }/>
                        </MetroBlock>
                        <MetroBlock size={ 2 }  >
                            <BodyBox type="title" 
                                html={ page.body } 
                                onClick={ () => this.setState({readMore: true}) }/>
                        </MetroBlock>
                        { page.metro_layout && page.metro_layout?.metro_blocks.map((metroBlock: MetroBlockData, index:number) => (
                            <MetroContent
                                key={ index } 
                                metroBlock={ metroBlock }
                                parentPath={ page.alias }/>
                        )) }
                    </MetroLayout>
                ) }
                { page && (
                    <Modal open={ this.state.readMore || !!boxData}>
                        <div style={ {
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            margin: '15px',
                            zIndex: 1200} }>
                            <IconButton size={ 30 } svgName='closeWhite' linkTo={ `/${page.alias}` } onClick={ () => this.setState({readMore: false})}/>
                        </div>
                        {
                           this.state.readMore 
                           ? (
                            <div style={ {height: '100%'} }>
                                <div className={ exhibitionModalClassName } 
                                >
                                    <h2>{ page.title }</h2>
                                    <div dangerouslySetInnerHTML={{__html:page.body}} className={ exhibitionBodyClassName }/>
                                </div>
                            </div>
                           )
                           : (
                               boxData && (
                                   <BoxPage boxData={ boxData }/>
                               )
                           )

                        }
                        
                    </Modal>
                ) }
            </Content>
           
        );
    }
}

export default connectState(Page);

import { style } from 'typestyle';

// import {  } from 'typestyle/lib/internal';
import { COLOR_BACKGROUND, COLOR_PRIMARY } from '../../style/constants';
import Color from 'color';

export const getGradient = (color: string): string =>
    `linear-gradient(0deg, ${color} 40%, ${Color(color).fade(1)} 100%)`;

export const readMoreClassName = style({
    height: '100%',
    overflow: 'hidden'
});

export const readMoreButonPadClassName = style({
    $debugName: 'readMoreButonPadClassName',
    position: 'absolute',
    width: '100%',
    height: '100px',
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: COLOR_BACKGROUND
});

export const readMoreButtonClassName = style({
    $debugName: 'readMoreButtonClassName',

    display: 'block',
    position: 'relative',
    margin: '62px auto 0',
    fontStyle: 'italic',
    fontSize: '14px',
    height: '38px',
    width: 'auto',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    boxShadow: `${COLOR_PRIMARY} 0px 0px 8px -4px`,
    outline: 'none',
    cursor: 'pointer',
    color: 'inherit',
    padding: '10px 15px',
    $nest: {
        '&:hover': {
            opacity:0.5
        }
    }
});

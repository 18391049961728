import { style, media } from 'typestyle';
import { COLOR_BACKGROUND, FONT_FAMILY_1, MEDIA_LG, MEDIA_MD } from '../../../style/constants';

export const textBoxClassName = style({
    backgroundColor: '#193264',
    color: COLOR_BACKGROUND
});

export const textBoxFormatClassName = style(
    {
        color: COLOR_BACKGROUND,
        fontSize: '30px',
        lineHeight: '1.4',
        fontFamily: FONT_FAMILY_1,
        fontStyle: 'italic',

        $nest: {
            '*': {
                color: COLOR_BACKGROUND,
                fontSize: '30px',
                lineHeight: '1.4',
                fontFamily: FONT_FAMILY_1,
                fontStyle: 'italic'
            }
        }
    },
    media(MEDIA_MD, {
        fontSize: '34px',

        $nest: {
            '*': {
                fontSize: '34px'
            }
        }
    }),
    media(MEDIA_LG, {
        fontSize: '52px',

        $nest: {
            '*': {
                fontSize: '52px'
            }
        }
    })
);
import React from 'react';
import { Box } from '../box.component';
import { textBoxClassName, textBoxFormatClassName } from './text-box.styles';
import { BoxData, AppActions } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { ReadMore } from '../../read-more/read-more.component';

interface TextBoxProps extends AppActions {
    box: BoxData,
    linkTo?: string
}

const TextBox: React.FC<TextBoxProps> = ({
    box,
    linkTo,
    setAppState
}) => (
    <Box bgImage={ box.image_url } className={ textBoxClassName } linkTo={ linkTo } onClick={ () => setAppState({
        modalBox: box,
        modalCustomContent: false
    }) }>
        { box.body && (
            <ReadMore fadeOutColor="#193264">
                <div className={ textBoxFormatClassName } dangerouslySetInnerHTML={ {__html: box.body} }/>
            </ReadMore>
        ) }
    </Box>
);

export default connectAppActions()(TextBox);
import React from 'react';
import { Link } from 'react-router-dom';
import { Svg, SvgName } from '../svg/svg.component';
import { iconButtonClassName } from './icon-button.styles';

interface iconButtonProps {
    svgName: SvgName,
    size?: number,
    onClick?(): void,
    linkTo?: string,
    href?: string,
    blank?: boolean,
    preventDefault?: boolean,
    stopPropagation?: boolean,
    gallerySubIcons?: boolean,
}

export const IconButton: React.FC<iconButtonProps> = ({
    svgName,
    size = 30,
    onClick,
    linkTo,
    href,
    blank,
    preventDefault = false,
    stopPropagation = false,
    gallerySubIcons = false
}) => (
    !linkTo && !href
    ? (
        <button
            className={ iconButtonClassName }
            onClick={ e => {
                if (preventDefault)
                    e.preventDefault();

                if (stopPropagation)
                    e.stopPropagation();
                
                if (onClick)
                    onClick();
            } }
            style={ {
                width: `${size}px`,
                height: `${size}px`
            } }>
                <Svg size={ size } name={ svgName }/>
        </button>
    )
    : (
        linkTo
        ? (
            <Link
                className={ iconButtonClassName }
                to={{ pathname: linkTo }  }
                onClick={ (e) => {
                    if (preventDefault)
                        e.preventDefault();

                    if (stopPropagation)
                        e.stopPropagation();

                    if (onClick)
                        onClick();
                } }
                target={ blank ? '_blank' : '_self' }
                style={ {
                    width: `${size}px`,
                    height: `${size}px`
                } }>
                    <Svg size={ size } name={ svgName }/>
            </Link>
        )
        : (
            <a
                className={ iconButtonClassName }
                href={ href }
                onClick={ (e) => {
                    if (preventDefault)
                        e.preventDefault();

                    if (stopPropagation)
                        e.stopPropagation();

                    if (onClick)
                        onClick();
                } }
                target={ blank ? '_blank' : '_self' }
                style={ {
                    width: `${size}px`,
                    height: `${size}px`
                } }>
                  
                    <Svg size={ size } name={ svgName }/>
                    
            </a>
        )
    )
);

import React from 'react';
import Header from './components/header/header.component';
import {
	Home,
	Exhibitions,
	Exhibition,
	Page
} from './components/pages';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import './style/rules';
import { getAll } from './api';
import { connect } from 'react-redux';
import { AppState } from './state/interfaces';
import { setAppState } from './state/actions';
import { parseData, addAliasToUnknownData, addFrontEndMetadataToExhibitionList } from './utils';
	
const mapStateToProps = (state: any): AppState => state.AppReducer;
const mapDispatchToProps = {setAppState};

interface AppStateProps extends AppState {
	setAppState(state: AppState): void
}

interface ScrollAppPageState {
    isScrolling: boolean,
    top: number
}

class App extends React.PureComponent<AppStateProps, ScrollAppPageState> {
	divRef = React.createRef<HTMLDivElement>();
	timer:any = null;

	public state: ScrollAppPageState = {
        isScrolling: false,
    	top: 0
	};
	
	componentDidMount() {
		this.setAllData();
		
	}

	async setAllData() {
		try {
			const allData: any = await getAll();
			const parsedData = parseData(allData);

			if (parsedData.exhibitions) {
				addAliasToUnknownData(parsedData.exhibitions);
				addFrontEndMetadataToExhibitionList(parsedData.exhibitions);
			}

			this.props.setAppState(parsedData);
		}
		catch(err) {
		}
	}

	render() {
		const {
			layoutType,
			pages
		} = this.props;

		return (
			<div className="App-WOP" >
				<Router>
					<Header layoutType={ layoutType } pages={ pages || [] }/>
					<Switch>
						<Route path="/" exact component={ Home }/>
						<Route path="/exhibitions" exact component={ Exhibitions }/>
						<Route path="/exhibitions/:alias/:boxAlias?" exact component={ Exhibition } />
						<Route path="/:alias/:boxAlias?" exact component={ Page }/>
						<Route path="/">
							404
						</Route>
					</Switch>
				</Router>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import  React from 'react';
import { Box, BoxCaption } from '../box.component';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions, getColorBlue } from '../box.utils';
import { AppActions } from '../../../state/interfaces';

interface DocumentBoxProps extends AppActions {
    box: BoxData
}
 
const DocumentBox: React.FC<DocumentBoxProps> = ({
    box,
    setAppState,
}) => (
    <Box
        bgImage={box.image_url}
        bgColor={box.image_url ? undefined : getColorBlue()}
        externalLink={box.external_link}
        onClick={ () => setAppState({
            modalCustomContent: false,
            modalBox: null
        })}>
        <BoxCaption
            name= {(box.document_title)? "Document:": ''}
            title={ box.title}
            documentBtn = {box.external_link}
        />
    </Box>
);

export default connectAppActions()(DocumentBox);

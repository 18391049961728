import { connect } from 'react-redux';
import { AppState, AppActions, ExhibitionData } from '../../../state/interfaces';
import { setAppState } from '../../../state/actions';

export interface ExhibitionStateProps extends AppActions {
    exhibitions?: ExhibitionData[] | null,
    loadingExhibitions?: boolean,
    focusedExhibitionOrder?: number,
    selectedExhibitionAlias?: string | null,
}

const mapStateToProps = (state: any): AppState => {
    return {
        exhibitions: state.AppReducer.exhibitions || null,
        loadingExhibitions: Boolean(state.AppReducer.loadingExhibitions),
        focusedExhibitionOrder: state.AppReducer.focusedExhibitionOrder > 0
            ? state.AppReducer.focusedExhibitionOrder
            : 0,
        selectedExhibitionAlias: state.AppReducer.selectedExhibitionAlias || null,
    }
};

const mapDispatchToProps: AppActions = {
    setAppState
};

export default connect(mapStateToProps, mapDispatchToProps);
import { style, cssRule, media } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import {
    COLOR_BACKGROUND,
    FONT_FAMILY_2,
    FONT_WEIGHT_BOLD,
    MEDIA_LG,
    MEDIA_MD
} from '../../style/constants';
import {
    metroBlockSize2ClassName,
    metroBlockSize1ClassName,
    metroBlockSize0ClassName
} from '../metro/metro.styles';

export const boxClassName = style(
    {
        $debugName: 'boxClassName',

        display: 'block',
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'visible',
        backgroundOrigin: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '15px',
        boxSizing: 'border-box',
        cursor: 'pointer'
    },
    media(MEDIA_MD, {
        padding: '15px'
    }),
    media(MEDIA_LG, {
        padding: '30px'
    })
);

export const boxClickableClassName = style({
    cursor: 'pointer'
});

export const boxBGVideoClassName = style({
    position: 'absolute'
});

export const innerBoxClassName = style({
    $debugName: 'innerBoxClassName',

    display: 'block',
    position: 'relative',
    height: '100%',
    overflow: 'visible',
    margin: '0 auto',
    boxSizing: 'border-box',
    lineHeight: 1
});

export const innerBoxXLClassName = style({
    margin: '30px'
});

export const boxCaptionClassName = style(
    {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        width: '100%',
        height: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        color: COLOR_BACKGROUND,
        fontFamily: FONT_FAMILY_2,
        fontWeight: FONT_WEIGHT_BOLD,
        fontSize: '20px',

        $nest: {
            'small': {
                fontSize: '14px',
                display: 'block'
            }
        }
    },
    media(MEDIA_LG, {
        fontSize: '26px',

        $nest: {
            'small': {
                fontSize: '16px',
                display: 'block'
            }
        }
    })
);

export const boxCaptionLeftClassName = style({
    flexGrow: 0,
    flexShrink: 1,
    $nest: {
        '& > div': {
            position: 'absolute',
            bottom: 0,
            textShadow: '#00000080 1px 1px 10px'
        }
    }
});

export const boxCaptionPersonLeftClassName = style({
    width: '100!important%',
})

export const boxCaptionLeftTypeClassName = style({
    width: '75%',
})

export const boxCaptionRightClassName = style({
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',

    $nest: {
        '& a, & button': {
            display: 'block'
        }
    }
});

export const boxCaptionDescriptionClassName = style({
    fontSize: '16px',
    margin: '5px 0 0 0'
});

const largeMargin = 45;

const innerBoxLarge: NestedCSSProperties = {
    margin: `${largeMargin}px`,
    height: `calc(100% - ${largeMargin * 2}px)`
};

const captionBoxLarge: NestedCSSProperties = {
    margin: `-${largeMargin}px`,
    width: `calc(100% + ${largeMargin * 2}px)`
};

// CSS RULES

// cssRule(`.${metroBlockSize0ClassName} > div > div > div > .${innerBoxClassName}`, innerBoxLarge);
// cssRule(`.${metroBlockSize1ClassName} > div > div > div > .${innerBoxClassName}`, innerBoxLarge);
// cssRule(`.${metroBlockSize2ClassName} > div > div > div > .${innerBoxClassName}`, innerBoxLarge);
// cssRule(`.${metroBlockSize2ClassName} > div > div > div > .${innerBoxClassName} > .${boxCaptionClassName}`, captionBoxLarge);

// cssRule('@media print', {
//     $nest: {
//         body: {
//             background: 'white'
//         }
//     }
// });

export const boxCaptionPopupClassName = style({
    display: 'block',
    position: 'absolute',
    background: 'white',
    zIndex: 99,
    right: 0,
    padding: '15px',
    color: 'red',
    borderRadius: '5px',
    boxShadow: 'black 1px 1px 18px -9px'
});
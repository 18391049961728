import { style, media } from 'typestyle';
import {
    FONT_FAMILY_1, MEDIA_LG, MEDIA_MD, MEDIA_SM, MEDIA_XL
} from '../../../style/constants';
import {
    mediaSet
} from '../../../style/utils';

const homePadding = mediaSet('75px');
const topAreaHeight = mediaSet('120px');
const bottomAreaHeight = mediaSet('60px');

export const homeClassName = style(
    {
        $debugName: 'homeClassName',

        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        boxSizing: 'border-box',
        overflow: 'hidden',
        padding: '100px 15px 25px 15px'
    },
    media(MEDIA_MD, {
        padding: '75px 45px 45px 45px'
    }),
    media(MEDIA_LG, {
        padding: '75px'
    })
);
export const topHomeClassName = style(
    {
        flexGrow: 0,
        flexShrink: 0,
        height: '120px',
        margin: 0,
        zIndex: 2,

        $nest: {
            '& > img': {
                maxHeight: '100%',
                width: 'auto',
                margin: 0,
                maxWidth: '100%'
            }
        }
    }
);
export const middleHomeClassName = style({
    flexGrow: 1,
    flexShrink: 0,
    margin: '0 0 10px 0',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start'
}, media(MEDIA_MD, {
    alignItems: 'center'
}));
export const bottomHomeClassName = style(
    {
        $debugName: 'bottomHomeClassName',

        flexGrow: 0,
        flexShrink: 0,
        position: 'relative',
        height: '50px',
        zIndex: 2,

        $nest: {
            '&:before, &:after': {
                display: 'block',
                content: '""',
                clear: 'both'
            }
        }
    },
    media(MEDIA_MD, {
        height: '40px'
    }),
    media(MEDIA_LG, {
        height: '60px'
    })
);
export const bottomLeftHomeClassName = style(
    {
        float: 'left',
        width: '60%',
        height: '100%',
        position: 'relative',

        $nest: {
            '& > a > img': {
                display: 'block',
                width: 'auto',
                position: 'relative',
                margin: '0 0 20px 0',
                padding: 0,
                border: 0
            }
        }
    },
    media(MEDIA_MD, {
        $nest: {
            '& > a > img': {
                float: 'left',
                margin: '0 45px 0 0'
            }
        }

    })
);

export const bottomLeftHomeUnClassName = style(
    {
        height: '40px',
    },
    media(MEDIA_MD, {
        height: '93%'
    })
)

export const bottomLeftHomeDPAAClassName = style({
        height: '45px',
    },
    media(MEDIA_MD, {
        height: '100%'
    })
);

export const bottomRightHomeClassName = style(
    {
        float: 'left',
        width: '40%',
        height: '50%',
        position: 'relative'
    },
    media(MEDIA_MD, {
        marginTop: 0,
        height: '100%'
    })
);

export const enterExhibitionBtnClassName = style(
    {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: '100%',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 'auto',
        fontFamily: FONT_FAMILY_1,
        lineHeight: '25px',
        fontStyle: 'italic',
        textAlign: 'right',
        fontSize: '25px',
        textDecoration: 'none',
        color: '#FFF',
        alignItems: 'center',
        top: 0,

        $nest: {
            '& > img': {
                display: 'block',
                float: 'right',
                height: '100%',
                width: 'auto',
                margin: '0 0 0 15px'
            }
        }
    },
    media(MEDIA_MD, {
        fontSize: '32px',
        lineHeight: '32px'
    }),
    media(MEDIA_LG, {
        fontSize: '32px',
        lineHeight: '32px'
    })
);

export const diplomacy4PeaceBigLogoClassName = style({
    display: 'block',
    margin: '30px 0',
    padding: 0,
    border: 'none',
    width: '100%',
    maxWidth: '800px'
});
import React from 'react';
import { Box, BoxProps } from '../box.component';
import {
    eventBoxClassName,
    eventBoxInnerClassName,
    eventBoxContentClassName,
    eventBoxButtonPadClassName,
    eventBoxButtonClassName,
    eventBoxTypeTitleClassName,
    eventBoxTypeBodyClassName,
    eventBoxTypeBodyContrastClassName,
    eventBoxexternalLinkButtonClassName
} from './event-box.styles';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { AppState } from '../../../state/interfaces';
import { exhibitionBodyClassName, exhibitionOrderClassName } from '../../pages/exhibition/exhibition.styles';
import { FONT_WEIGHT_LIGHT } from '../../../style/constants';
import { zeroPad } from '../../../utils';
import { IconButton} from '../../icon-button/icon-button.component';

interface EventBoxProps extends BoxProps {
    type?: 'title' | 'body' | 'body-contrast',
    box?: BoxData,
    setAppState?(state: AppState): void,
    exhibitionMainBlock?: {
        order: number,
        caption: string,
        description: string
    },
    html?: string,
    linkTo?: string
}

interface EventBoxState {
    readMoreButton: boolean
}

export class EventBox extends React.PureComponent<EventBoxProps, EventBoxState> {
    private contentRef: React.RefObject<HTMLDivElement> = React.createRef();
    public state: EventBoxState = {
        readMoreButton: false
    }

    componentDidMount() {
        this.checkContentOverflow();
    }

    componentDidUpdate() {
        this.checkContentOverflow();
    }

    checkContentOverflow() {
        if (this.contentRef) {
            const contentElement = this.contentRef.current;

            this.setState({
                readMoreButton: !!contentElement && (
                    contentElement.scrollHeight > contentElement.offsetHeight
                )
            });
        }
    }

    eventBoxClassNames(): string {
        const {
            type = 'body'
        } = this.props;

        return eventBoxClassName + ' ' + eventBoxTypeBodyClassName;
    }

    render() {
        const {
            exhibitionMainBlock,
            onClick,
            box,
            setAppState,
            html,
            linkTo
        } = this.props;

        return (

            box && (
                <Box linkTo={ linkTo }  className={ this.eventBoxClassNames() }>
                    <div className={ eventBoxInnerClassName }>
                        <div className={ eventBoxContentClassName } ref={ this.contentRef }>

                            { box.title && <h2>{ box.title }</h2> }
                            { box.body && (
                                <div className={ exhibitionBodyClassName } dangerouslySetInnerHTML={ {
                                    __html: box.body
                                } }/>
                            ) }

                        </div>
                        { this.state.readMoreButton && (
                            <div className={ eventBoxButtonPadClassName }>
                                <button className={ eventBoxButtonClassName } type="button"
                                    onClick={ () => this.state.readMoreButton ? (setAppState && setAppState({
                                        modalCustomContent: false,
                                        modalBox: box
                                    })) : undefined }>
                                    Read More
                                </button>
                            </div>
                        ) }

                    </div>
                    <div className={ eventBoxexternalLinkButtonClassName }>
                    { box.external_link && <IconButton svgName="externalLink" size={ 80 } linkTo={ box.external_link } blank/> }
                    </div>
                </Box>

            )
        );
    }
};

export default connectAppActions()(EventBox);






// import React from 'react';
// import { BoxData } from '../../../state/interfaces';
// import { IconButton } from '../../icon-button/icon-button.component';
// import { Box } from '../box.component';
// import { eventBoxClassName, eventBoxTitleClassName, eventBoxSubtitleClassName, eventBoxBodyFormatClassName, eventBoxButtonPadClassName } from './event-box.styles';

// interface EventBoxProps {
//     box: BoxData
// }

// export const EventBox: React.FC<EventBoxProps> = ({
//     box
// }) => (
//     <Box className={ eventBoxClassName }>
//         <div className={ eventBoxTitleClassName } dangerouslySetInnerHTML={ {__html: box.title || ''} }/>
//         <div className={ eventBoxSubtitleClassName }>
//             { box.subtitle }
//         </div>
//         <div className={ eventBoxBodyFormatClassName } dangerouslySetInnerHTML={ {__html: box.body || ''} }/>
//         <div className={ eventBoxButtonPadClassName }>
//             { box.external_link && <IconButton svgName="externalLink" size={ 80 } linkTo={ box.external_link } blank/> }
//         </div>
//     </Box>
// );

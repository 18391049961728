import React, { useRef, useEffect, useState, ReactNode} from 'react';
import {
    boxClassName,
    boxClickableClassName,
    boxBGVideoClassName,
    innerBoxClassName,
    innerBoxXLClassName,
    boxCaptionClassName,
    boxCaptionLeftClassName,
    boxCaptionRightClassName,
    boxCaptionDescriptionClassName,
    boxCaptionPopupClassName,
    boxCaptionPersonLeftClassName,
    boxCaptionLeftTypeClassName
} from './box.styles';
import { IconButton } from '../icon-button/icon-button.component';
import { useHistory } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import { classes } from 'typestyle';

export interface BoxProps {
    className?: string,
    bgImage?: string | null,
    bgColor?: string,
    bgVideo?: string | null,
    xl?: boolean,
    onClick?(): void,
    linkTo?: string | null,
    externalLink?: string,
    id?:string,
    children?: React.ReactNode
}

interface BoxCaptionProps {
    name?: string,
    title?: string,
    description?: string,
    playBtn?(): void,
    seeBtn?(): void,
    shareBtn?: string,
    linkBtn?: string,
    documentBtn?: string,
    websiteBtn?: string,
    playLinkBtn?: string,
    type?: string,
    className?: string
}

interface BoxCaptionState {
    shareTooltipOpen: boolean
}

export const Box: React.FC<BoxProps> = ({
    children,
    bgImage,
    bgColor,
    bgVideo,
    className,
    xl,
    onClick,
    linkTo,
    externalLink,
    id
}) => {
    const history = useHistory();
    const domRef:any = useRef<HTMLDivElement>(null);
    const[scrollId, setScrollId] = useState();

    const handleObserver = ((entries:any) => {
        entries.forEach((entry:any) => {  
            console.log(entry.isIntersecting,entry, entry.target.id,'intersecting');
            if(entry.isIntersecting){
                setScrollId(entry.target.id);
                const numberAlias = Number((entry.target.id).match(/\d+/)[0]);
             //console.log(entry.isIntersecting,entry, entry.target.id,'intersecting');
                history.push(`/exhibitions/alias-exhibition-0${numberAlias}`)
            }
        })
    })

    useEffect(() => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 0
         };

        const observer = new IntersectionObserver(handleObserver, options);

        if (null !== domRef.current) {
            observer.observe(domRef.current);
             return()=> observer.unobserve(domRef.current)
        }
    }, []);

    return (
        id
        ? (
            <div
                id={ id }
                className={ [
                    boxClassName,
                    className ? className : '',
                    onClick ? boxClickableClassName : ''
                ].join(' ') }
                onClick={ (event) => {
                    if (onClick) {
                        event.preventDefault();
                        onClick();
                        
                    }
                    if (linkTo) {
                        event.stopPropagation();
                        history.push(linkTo);
                    }
                    if( externalLink) {
                        window.open(externalLink)
                    }
                } }
                style={ (bgImage || bgColor)
                    ? {
                        backgroundImage: `url(${bgImage})`,
                        backgroundColor: bgColor
                    }
                    : undefined
                }>
            
                { bgVideo && (
                    <video poster={ bgImage || '' } autoPlay loop muted width="100%" className={ boxBGVideoClassName } src={ bgVideo }/>

                ) }
                <div className={ [innerBoxClassName, xl ? innerBoxXLClassName : ''].join(' ') }>
                    { children }
                </div>
            </div>
        ) :
        (
            <div
                className={ [
                    boxClassName,
                    className ? className : '',
                    onClick ? boxClickableClassName : ''
                ].join(' ') }
                onClick={ (event) => {
                    if (onClick) {
                        event.preventDefault();
                        onClick();
                        
                    }
                    if (linkTo) {
                        event.stopPropagation();
                        history.push(linkTo);
                    }
                    if( externalLink) {
                        window.open(externalLink)
                    }
                } }
                style={ (bgImage || bgColor)
                ? {
                    backgroundImage: `url(${bgImage})`,
                    backgroundColor: bgColor
                }
                : undefined
            }>
            
            { bgVideo && (
                <video poster={ bgImage || '' } autoPlay loop muted width="100%" className={ boxBGVideoClassName } src={ bgVideo }/>

            ) }
            <div className={ [innerBoxClassName, xl ? innerBoxXLClassName : ''].join(' ') }>
                { children }
            </div>
        </div>
        )
        
    );
};

export class BoxCaption extends React.PureComponent<BoxCaptionProps, BoxCaptionState> {
    public state: BoxCaptionState = {
        shareTooltipOpen: false
    };

    private tooltipEl: React.RefObject<HTMLDivElement> = React.createRef();

    private openShare() {
        const $this = this;

        this.setState({
            shareTooltipOpen: true
        });

        function closeEvent() {
            window.removeEventListener('click', closeEvent);

            $this.setState({
                shareTooltipOpen: false
            });
        }

        window.addEventListener('click', closeEvent);
    }

    render() {
        const {
            name,
            title,
            description,
            linkBtn,
            playBtn,
            seeBtn,
            shareBtn,
            documentBtn,
            websiteBtn,
            playLinkBtn,
            className,
            type
        } = this.props;

        const { shareTooltipOpen } = this.state;

        return (
            <div className={ classes(boxCaptionClassName, className) } >
                <div className={ boxCaptionLeftClassName }>
                    <div className={ type== 'person'? boxCaptionPersonLeftClassName: boxCaptionLeftTypeClassName}>
                        { name && <small>{ name }</small> }
                        { title }
                        { description && (
                            <div className={ boxCaptionDescriptionClassName }>
                                { description }
                            </div>
                        ) }
                    </div>
                </div>
                <div className={ boxCaptionRightClassName }>
                    { playBtn && <IconButton size={ 80 } svgName="play" onClick={ playBtn }/> }
                    { seeBtn && <IconButton size={ 78 } svgName="arrowRight" onClick={ seeBtn }/> }
                    { linkBtn && <IconButton size={ 80 } svgName="externalLink" href={ linkBtn } stopPropagation blank/> }
                    { documentBtn && <IconButton size={ 80 } svgName="documentLink" href={ documentBtn } stopPropagation blank/> }
                    { websiteBtn && <IconButton size={ 80 } svgName="websiteLink" href={ websiteBtn } stopPropagation blank/> }
                    { playLinkBtn && <IconButton size={ 80} svgName="play" href={playLinkBtn } stopPropagation blank/> }
                    { shareBtn && (
                        <div>
                            <IconButton size={ 50 } svgName="share"/>
                            {/* <div className={ boxCaptionPopupClassName } ref={ this.tooltipEl } style={ {display: shareTooltipOpen ? 'block' : 'none'} }>
                                SOCIAL
                            </div> */}
                        </div>
                    ) }
                </div>
            </div>
        );
    }
}
import React from 'react';
import { Box, BoxCaption } from '../box.component';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { AppActions } from '../../../state/interfaces';

interface VideoBoxProps extends AppActions {
    box: BoxData,
    linkTo?: string | null
}

const VideoBox: React.FC<VideoBoxProps> = ({
    box,
    linkTo,
    setAppState
}) => (
    <Box bgImage={ box.video_thumbnail_url || box.image_url } linkTo={ (box.external_link)?null:linkTo } externalLink ={box.external_link} onClick={ () => setAppState({
        modalCustomContent: false,
        modalBox: (box.external_link)?null:box
    }) }>
        { box.external_link && (
            <BoxCaption
                name="Video:"
                title={ box.title }
                playLinkBtn = { box.external_link }
            />
        )}
        <BoxCaption
            name="Video:"
            title={ box.title }
            playBtn={ () => {} }
        />
    </Box>
);

export default connectAppActions()(VideoBox);
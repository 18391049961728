import { style } from 'typestyle';

export const imageSlideshowBoxClassName = style({
    padding: '0 !important'
});

export const imageSlideshowBoxCursorDefaultClassName = style({
    cursor: 'default'
});

export const imageSlideshowDynamicImageClassName = style({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: '#365c90'
});

export const imageSlideshowButtonListClassName = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    position: 'absolute',
    bottom: '42px',
    width: 'calc(100% - 400px)',
    zIndex: 100,
    left: '50%',
    transform: 'translateX(-50%)',

    $nest: {
        'li': {
            display: 'block',
            margin: 0,
            padding: 0,

            $nest: {
                'button': {
                    display: 'block',
                    border: 0,
                    cursor: 'pointer',
                    margin: '8px',
                    padding: 0
                },
                '&:first-child': {
                    borderTopLeftRadius: '16px',
                    borderBottomLeftRadius: '16px'
                },
                '&:last-child': {
                    borderTopRightRadius: '16px',
                    borderBottomRightRadius: '16px'
                }
            }
        }
    }
});

export const imageSlideshowButtonDirectionClassName = style({
    background: 'transparent',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '19px',
    color: 'white',
    width: '16px',
    height: '12px',
    lineHeight: '12px',
    opacity: .75
});

export const imageSlideshowButtonNumberClassName = style({
    background: 'white',
    opacity: .2,
    width: '12px',
    height: '12px',
    margin: '6px',
    borderRadius: '50%'
});

export const imageSlideshowButtonNumberActiveClassName = style({
    opacity: 1
});

export const imageSlideshowCaptionClassName = style({
    margin: '0 30px 30px',
    width: 'calc(100% - 60px)'
});

import React, { useRef, useEffect, useState, ReactNode, useLayoutEffect} from 'react';
import {
    metroBlockClassName,
    metroBlockInnerClassName,
    metroBlockAreaClassName,

    metroBlockSize0ClassName,
    metroBlockSize1ClassName,
    metroBlockSize2ClassName,
    metroBlockSize3ClassName,
    metroBlockSize4ClassName,

    metroBlockGroupClassName,
    metroBlockGroupClassNameLeft,
    childBlockClassName,

    exhibitionBoxFadeInFromBottom,
    exhibitionBoxIsVisible,
    sizeWidths
} from './metro.styles';
import { useHistory } from "react-router-dom";


interface MetroBlockProps {
    size?: number,
    childOf?: number,
    group?: boolean,
    id?:string,
    className?:string,
    boxAlias?:boolean,
    leftChildBlock?:boolean,
    firstLoaded?:boolean,
    children?: React.ReactNode
}

const sizeClassNames = [
    metroBlockSize0ClassName,
    metroBlockSize1ClassName,
    metroBlockSize2ClassName,
    metroBlockSize3ClassName,
    metroBlockSize4ClassName
];

export const MetroBlock: React.FC<MetroBlockProps> = ({
    children, size = 2, childOf, group, id, boxAlias,firstLoaded, leftChildBlock
}) => {
    const generatedWidth: string = (childOf !== undefined) ? `${((sizeWidths[size] * 100)/ sizeWidths[childOf])}%` : '';
    const [isVisible, setVisible] = useState(false);
    const[scrollId, setScrollId] = useState<any>(`/exhibitions/${id}`);
    const[idIntersecting, setIdIntersecting] = useState<any>(null);
    const[intersecting, setIntersecting] = useState(false);
    const [firstUrlAlias, setFirstUrlAlias] = useState(true);
    const domRef:any = useRef<HTMLDivElement>(null);
    const history = useHistory();
    
    useEffect(() => {
        if(idIntersecting){
            console.log('holiiiiiiii')
            if(!firstUrlAlias){
                history.replace(`/exhibitions/${idIntersecting}`)
            }
        }
                         
    },[idIntersecting])

    useLayoutEffect(() => {//1
        var options = {
            root:  null,
            rootMargin: "30px 0px 20px 0px",
            threshold: 0
        };
        
        const observer = new IntersectionObserver(((entries:any) => {
            entries.forEach((entry:any) => {  //1
                setVisible(entry.isIntersecting);
                
                if(entry.isIntersecting){ 
                    //setIntersecting(true)
                    if(id ){
                        
                            if(boxAlias == null || boxAlias ==  true){
                               
                            }
                            else{
                                
                                const idScroll = `/exhibitions/${entry.target.id}`;
                                const windowPath = window.location.pathname ;//09
                               
                                if(firstLoaded || firstLoaded == null){
                                   
                                }else{
                        
                                    if(idScroll !== windowPath){
                                        history.replace(`/exhibitions/${id}`)
                                    }
                                }
                                    // if(idScroll !== windowPath){
                                    //     history.replace(`/exhibitions/${id}`)
                                    // }else{
                                    //     console.log('igual', id)
                                    // }
                                
                                    //setFirstUrlAlias(true)
                                   
                                    
                                
    
                                
                                // if(scrollId == windowPath){
                                //     if(firstUrlAlias){
                                //         setFirstUrlAlias(false);        
                                //     }
                                   
                                //     else{
                                //         return()=> observer.unobserve(domRef.current)
                                        
                                //     }       
                                // }else{
                                   
                                //     if(firstUrlAlias){
                                //         setFirstUrlAlias(false);
                                //     }else{
                                       
                                //         setFirstUrlAlias(false)
                                //         setScrollId(`/exhibitions/${entry.target.id}`)
                                //         setIdIntersecting(entry.target.id)
                                       
                                //     }
                                       
                                // }
                            }
                       
                       
                    }
                }
               
            })
        }), options);
        if (null !== domRef.current) {
            observer.observe(domRef.current);
             return()=> observer.unobserve(domRef.current)
        }
            
    }, [boxAlias, firstLoaded]);

    return (
        id ? (   
            <div className={ [
                metroBlockClassName,
                sizeClassNames[size],
                group ? metroBlockGroupClassName : '',
                leftChildBlock ? metroBlockGroupClassNameLeft:'',
                (childOf !== undefined) ? childBlockClassName : '',
                isVisible ? exhibitionBoxIsVisible : "" ,
                exhibitionBoxFadeInFromBottom
            ].join(' ') }
            ref={domRef}
            id={id}
            >
                <div className={ metroBlockAreaClassName }>
                    <div className={ metroBlockInnerClassName }>
                        { children }
                    </div>
                </div>
            </div>
           
        ): (
            <div className={ [
                metroBlockClassName,
                sizeClassNames[size],
                group ? metroBlockGroupClassName : '',
                (childOf !== undefined) ? childBlockClassName : '',
                isVisible ? exhibitionBoxIsVisible : "" ,
                exhibitionBoxFadeInFromBottom
            ].join(' ') }
            ref={domRef}
            >
                <div className={ metroBlockAreaClassName }>
                    <div className={ metroBlockInnerClassName }>
                        { children }
                    </div>
                </div>
            </div>
        )
        
    )
};
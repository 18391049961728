import { style } from 'typestyle';

export const iconButtonClassName = style({
    display: 'inline-block',
    margin: 0,
    padding: 0,
    border: 0,
    overflow: 'hidden',
    background: 'transparent',
    cursor: 'pointer',
    outline: 'none',
});
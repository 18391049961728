import React from 'react';
import {
    chartClassName,
    chartInnerClassName,

    chartTitleClassName,
    chartSubtitleClassName,

    chartCountriesClassName,
    chartGraphicClassName,
    chartTableWrapperClassName,
    chartTablePercentageClassName,

    chartSummaryClassName,
    chartSummaryTitleClassName,
    chartSummaryNumberClassName,
    chartSummaryYearClassName,

    chartSliderClassName,
    chartSliderLineClassName,
    chartSliderTrackClassName,
    chartSliderHandlerClassName,
    chartSliderHandlerIconClassName,
    chartSliderFootClassName,

    chartBarClassName,
    chartBarFillClassName,

    chartFooterClassName
} from './interactive-veto-chart.styles';
import { exhibitionItemSvgClassName } from '../pages/exhibitions/exhibitions.style';
import { VETOE_DATA, getFilteredData, FilteredData, COUNTRY_COLORS } from './interactive-veto-chart.data';
import { listenMouseDown, initChart, updateChart, listenTouch } from './interactive-veto-chart.utils';
import { zeroPad } from '../../utils';
import handFlat from '../../images/hand-flat.png';
import handGradient from '../../images/hand-gradient.png';
import { isConstructorDeclaration } from 'typescript';

interface InteractiveVetoChartProps {

}
interface InteractiveVetoChartState {
    handlerPosition: number,
    currentDataIndex: number,
    currentSelection: FilteredData
}

export class InteractiveVetoChart extends React.PureComponent<InteractiveVetoChartProps, InteractiveVetoChartState> {
    public state: InteractiveVetoChartState = {
        handlerPosition: 0,
        currentDataIndex: 0,
        currentSelection: getFilteredData(0),
    };
    private trackRef = React.createRef<HTMLDivElement>();
    private chartObj: Chart | null = null;
    

    componentDidMount() {
        this.chartObj = initChart('vetoesChart', this.state.currentSelection.countries.map(countryData => countryData.total));
        
    }


    touchStartHandler(initialEvent: React.TouchEvent) {
        const initialPosition = initialEvent.changedTouches[0].pageX;
        const trackWidthPx = this.trackRef.current?.offsetWidth || 280;
        const initialHandlerPosition = this.state.handlerPosition;
        listenTouch((touchMoveEvent) => {
            const movement = touchMoveEvent.touches[0].pageX - initialPosition;
            const movementPercentage = (movement * 100) / trackWidthPx;
            const currentHandlerPosition = initialHandlerPosition + movementPercentage;
            const newHandlerPosition = Math.max(Math.min(currentHandlerPosition, 100), 0);
            const newDataIndex = Math.round((newHandlerPosition * (VETOE_DATA.length - 1)) / 100);
            if (newDataIndex !== this.state.currentDataIndex) {
                const newSelection = getFilteredData(newDataIndex);
                if (this.chartObj) {
                    updateChart(this.chartObj, newSelection.countries.map(countryData => countryData.total));
                }
                this.setState({
                    handlerPosition: newHandlerPosition,
                    currentDataIndex: newDataIndex,
                    currentSelection: newSelection
                });
            }
            else {
                this.setState({
                    handlerPosition: newHandlerPosition
                });
            }
        });
    }


    mouseDownHandler(initialEvent: React.MouseEvent ) {
        
        const initialPosition = initialEvent.pageX;
        const trackWidthPx = this.trackRef.current?.offsetWidth || 280;
        const initialHandlerPosition = this.state.handlerPosition;

        listenMouseDown((dragEvent: MouseEvent) => {
            const movement = dragEvent.pageX - initialPosition;
            const movementPercentage = (movement * 100) / trackWidthPx;
            const currentHandlerPosition = initialHandlerPosition + movementPercentage;
            const newHandlerPosition = Math.max(Math.min(currentHandlerPosition, 100), 0);
            const newDataIndex = Math.round((newHandlerPosition * (VETOE_DATA.length - 1)) / 100);

            if (newDataIndex !== this.state.currentDataIndex) {
                const newSelection = getFilteredData(newDataIndex);

                if (this.chartObj) {
                    updateChart(this.chartObj, newSelection.countries.map(countryData => countryData.total));
                }

                this.setState({
                    handlerPosition: newHandlerPosition,
                    currentDataIndex: newDataIndex,
                    currentSelection: newSelection
                });
            }
            else {
                this.setState({
                    handlerPosition: newHandlerPosition
                });
            }
        });
    }

    render() {
        const {
            currentDataIndex,
            currentSelection
        } = this.state;

        return (
            <div className={ chartClassName }>
                <div className={ chartInnerClassName }>
                    <div className={ chartTitleClassName }>
                        UN SECURITY COUNCIL VETOES
                    </div>

                    <div className={ chartSubtitleClassName }>
                        by permanent Security Council members (1946 to 1991)
                    </div>

                    <div className={ chartCountriesClassName }>
                        <div className={ chartGraphicClassName }>
                            <canvas id="vetoesChart" width="100" height="100"/>
                        </div>
                        <div className={ chartTableWrapperClassName }>
                            <table>
                                <tbody>
                                    { currentSelection.countries.map((countryData, index) => (
                                        <tr key={ index }>
                                            <td style={ {paddingRight: '15px'} }>
                                                { countryData.name }
                                            </td>
                                            <td width="50px">
                                                { zeroPad(countryData.total, 2) }
                                            </td>
                                            <td width="50px">
                                                <img src={ handFlat } alt="Vetoes" width="20"/>
                                            </td>
                                            <td className={ chartTablePercentageClassName } width="50px">
                                                { zeroPad(countryData.percentage, 2) }%
                                            </td>
                                            <td width="150px">
                                                <div className={ chartBarClassName }>
                                                    <div className={ chartBarFillClassName } style={ {
                                                        backgroundColor: COUNTRY_COLORS[index],
                                                        width: `${countryData.percentage}%`
                                                    } }/>
                                                </div>
                                            </td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={ chartSummaryClassName }>
                        <div className={ chartSummaryTitleClassName }>Total Vetoes</div>
                        <div className={ chartSummaryNumberClassName }>
                            { currentSelection.totalVetoes }
                            <img src={ handFlat } alt="Vetoes" width="20"/>
                        </div>
                        <div className={ chartSummaryYearClassName }>
                            { currentSelection.selectedYear }
                        </div>
                    </div>

                    <div className={ chartSliderClassName }>
                        <div className={ chartSliderLineClassName }>
                            <span></span><span></span><span></span><span></span>
                            <div className={ chartSliderTrackClassName } ref={ this.trackRef }>
                                <div
                                    className={ [chartSliderHandlerClassName,exhibitionItemSvgClassName].join(' ')}
                                    style={ {left: `${this.state.handlerPosition}%`, touchAction: 'none'} }
                                    onMouseDown={ (e: React.MouseEvent) => {e.preventDefault(); this.mouseDownHandler(e)} }
                                    onTouchStart={(e)=>{this.touchStartHandler(e)}}
                                    >
                                        <img className={ chartSliderHandlerIconClassName } src={ handGradient } alt="Slider handler"/>
                                </div>
                            </div>
                            <div className={ chartSliderFootClassName }>
                                <div>{ currentSelection.fromYear }</div>
                                <div>INTERACTIVE SLIDER</div>
                                <div>{ currentSelection.toYear }</div>
                            </div>
                        </div>
                    </div>

                    <div className={ chartFooterClassName }>
                        Move the interactive slider to see the vetoes from { currentSelection.fromYear } - { currentSelection.toYear }
                    </div>
                </div>
            </div>
        );
    }
}

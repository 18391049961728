import { style } from 'typestyle';
import { FONT_FAMILY_1, COLOR_BACKGROUND } from '../../../style/constants';

export const captionBoxClassName = style({
    $debugName: 'captionBox',
    backgroundColor: '#365c90',

    $nest: {
        '& > div': {
            // margin: '90px'
        }
    }
});

export const captionBoxInnerClassName = style({
    $debugName: 'captionBoxInner',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONT_FAMILY_1,
    fontStyle: 'italic',
    fontSize: '48px',
    color: COLOR_BACKGROUND,
    textAlign: 'center'
});
import React , { useRef, useEffect, useState, ReactNode} from 'react';
import {
    contentClassName,
    contentFullClassName,
    contentInnerClassName,
} from './content.styles';
import { useHistory } from "react-router-dom";

interface ContentProps {
    full?: boolean,
    backgroundImage?: string,
    backgroundColor?: string,
    className?:string,
    children?: React.ReactNode
}

export const Content: React.FC<ContentProps> = ({
    full = false,
    backgroundImage,
    backgroundColor,
    children
}) => {
    
    return (
        
        <div 
            className={ [contentClassName, full ? contentFullClassName : ''].join(' ')  }
            style={ (backgroundImage || backgroundColor) ? {
                backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
                backgroundColor: backgroundColor || 'transparent'
            } : {} } 
            >
                <div className={ contentInnerClassName }>
                    { children }
                </div>
        </div>
        
    );
};
import React from 'react';
import { Box, BoxProps } from '../box.component';
import {
    textBoxClassName,
    textBoxInnerClassName,
    textBoxContentClassName,
    textBoxButtonPadClassName,
    textBoxButtonClassName,
    textBoxTypeTitleClassName,
    textBoxTypeBodyClassName,
    textBoxTypeBodyContrastClassName
} from './body-box.styles';
import { BoxData } from '../../../state/interfaces';
import { connectAppActions } from '../box.utils';
import { AppState } from '../../../state/interfaces';
import { exhibitionBodyClassName, exhibitionOrderClassName } from '../../pages/exhibition/exhibition.styles';
import { FONT_WEIGHT_LIGHT } from '../../../style/constants';
import { zeroPad } from '../../../utils';

interface BodyBoxProps extends BoxProps {
    type?: 'title' | 'body' | 'body-contrast',
    box?: BoxData,
    setAppState?(state: AppState): void,
    exhibitionMainBlock?: {
        order: number,
        caption: string,
        description: string
    },
    html?: string,
    linkTo?: string
}

interface BodyBoxState {
    readMoreButton: boolean
}

class BodyBox extends React.PureComponent<BodyBoxProps, BodyBoxState> {
    private contentRef: React.RefObject<HTMLDivElement> = React.createRef();
    public state: BodyBoxState = {
        readMoreButton: false
    }

    componentDidMount() {
        this.checkContentOverflow();
    }

    componentDidUpdate() {
        this.checkContentOverflow();
    }

    checkContentOverflow() {
        if (this.contentRef) {
            const contentElement = this.contentRef.current;

            this.setState({
                readMoreButton: !!contentElement && (
                    contentElement.scrollHeight > contentElement.offsetHeight
                )
            });
        }
    }

    textBoxClassNames(): string {
        const {
            type = 'body'
        } = this.props;

        return textBoxClassName + ' ' + (
            (() => {
                switch(type) {
                    case 'title':
                        return textBoxTypeTitleClassName;

                    case 'body':
                        return textBoxTypeBodyClassName;

                    case 'body-contrast':
                        return textBoxTypeBodyContrastClassName;
                }
            })()
        );
    }

    render() {
        const {
            exhibitionMainBlock,
            onClick,
            box,
            setAppState,
            html,
            linkTo
        } = this.props;

        return (
            exhibitionMainBlock
            ? (
                <Box linkTo={ linkTo } onClick={ this.state.readMoreButton ? onClick : undefined } className={ this.textBoxClassNames() }>
                    <div className={ textBoxInnerClassName }>
                        <div className={ textBoxContentClassName } ref={ this.contentRef }>
                            <div className={ exhibitionOrderClassName }>
                                        { zeroPad(exhibitionMainBlock.order, 2) }
                            </div>
                            <h2>{ exhibitionMainBlock.caption }</h2>
                            <div dangerouslySetInnerHTML={{__html:exhibitionMainBlock.description }} style={ {fontWeight: FONT_WEIGHT_LIGHT} } />
                        </div>

                        { this.state.readMoreButton && (
                            <div className={ textBoxButtonPadClassName }>
                                <button className={ textBoxButtonClassName } type="button">
                                    Read More
                                </button>
                            </div>
                        ) }
                    </div>
                </Box>
            )
            : (
                html
                    ? (


                        <Box linkTo={ linkTo } onClick={ () => {
                            if (this.state.readMoreButton) {
                                if (setAppState) {
                                    setAppState({
                                        modalCustomContent: false,
                                        modalBox: box
                                    });
                                }

                                if (onClick) {
                                    onClick();
                                }
                            }
                        } } className={ this.textBoxClassNames() }>

                            <div className={ textBoxInnerClassName }>
                                <div className={ textBoxContentClassName } ref={ this.contentRef }>

                                    <div dangerouslySetInnerHTML={ {__html: html} }/>

                                </div>
                                { this.state.readMoreButton && (
                                    <div className={ textBoxButtonPadClassName }>
                                        <button className={ textBoxButtonClassName } type="button">
                                            Read More
                                        </button>
                                    </div>
                                ) }
                            </div>
                        </Box>


                    )
                    : (
                        box && (
                            <Box linkTo={ linkTo } onClick={ () => this.state.readMoreButton ? (setAppState && setAppState({
                                modalCustomContent: false,
                                modalBox: box
                            })) : undefined } className={ this.textBoxClassNames() }>

                                <div className={ textBoxInnerClassName }>
                                    <div className={ textBoxContentClassName } ref={ this.contentRef }>

                                        { box.title && <h2>{ box.title }</h2> }
                                        { box.body && (
                                            <div className={ exhibitionBodyClassName } dangerouslySetInnerHTML={ {
                                                __html: box.body
                                            } }/>
                                        ) }

                                    </div>
                                    { this.state.readMoreButton && (
                                        <div className={ textBoxButtonPadClassName }>
                                            <button className={ textBoxButtonClassName } type="button">
                                                Read More
                                            </button>
                                        </div>
                                    ) }
                                </div>
                            </Box>
                        )
                    )

            )

        );
    }
};

export default connectAppActions()(BodyBox);

import { style , media, keyframes} from 'typestyle';
import { scaleService } from 'chart.js';
import {
    MEDIA_MD_MAX
} from '../../style/constants';
export const svgClassName = style({
    display: 'inline-block',
    position: 'relative',
    margin: 0,
    padding: 0,
    border: 0,
});

const shake = keyframes({
    '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)'
    },
      
    '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)'
    },
    
    '30%, 50%, 70%':{
        transform: 'translate3d(-4px, 0, 0)'
      },
    
    '40%, 60% ':{
        transform: 'translate3d(4px, 0, 0)'
    }
});

export const svgClassImg = style({
    position: 'absolute',
    right:'-20px',
    bottom:'-20px',
    $nest: {
        '&:hover':{
            animation: `${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
            transform: 'translate3d(0, 0, 0)',
            backfaceVisibility: 'hidden',
            perspective: '1000px'
        }
    }
},
media(MEDIA_MD_MAX, {
    width: '50px',
    height: '50px',
    right:'-10px',
    bottom:'-10px',
})
)
import { style } from 'typestyle';
import { COLOR_BACKGROUND } from '../../style/constants';

export const modalClassName = style({
    position: 'fixed',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: COLOR_BACKGROUND,
    // zIndex: -100,
    zIndex: 1500,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 300ms'
});

export const modalOpenClassName = style({
    // zIndex: 1500,
    opacity: 1,
    pointerEvents: 'auto'
});
import React from 'react';
import { modalClassName, modalOpenClassName } from './modal.styles';

interface ModalProps {
    open?: boolean,
    children?: React.ReactNode
}

const onOpen = (): boolean => {
    const htmlEl: HTMLElement | null = document.querySelector('html');
    const appEl: HTMLDivElement | null = document.querySelector('.App-WOP');

    if (htmlEl && appEl) {
        htmlEl.style.overflowY = 'hidden';
        appEl.style.overflowY = 'hidden';
        return true;
    }

    return false;
};

const onClose = (): boolean => {
    const htmlEl: HTMLElement | null = document.querySelector('html');
    const appEl: HTMLDivElement | null = document.querySelector('.App-WOP');

    if (htmlEl && appEl) {
        htmlEl.style.overflowY = 'auto';
        appEl.style.overflowY = 'auto';
        return true;
    }

    return false;
};

export const Modal: React.FC<ModalProps> = ({
    children,
    open = true
}) => (
    <div className={ [modalClassName, open ? modalOpenClassName : ''].join(' ') }>
        {
            open
            ? onOpen() && children
            : onClose()
        }
    </div>
);
import { style, media, cssRule } from 'typestyle';
import { NestedCSSProperties, NestedCSSSelectors } from 'typestyle/lib/types';
import { MEDIA_LG, MEDIA_MD, MEDIA_SM, MEDIA_XL, MEDIA_XS_ONLY } from '../../style/constants';
import { bottomHomeClassName } from '../pages/home/home.styles';

const beforeAndAfterCSS: NestedCSSProperties = {
    display: 'block',
    content: '',
    clear: 'both',
    width: '100%',
    height: 'auto'
};

const clearCSS: NestedCSSSelectors = {
    '&:before': beforeAndAfterCSS,
    '&:after': beforeAndAfterCSS
};

export const metroLayoutClassName = style({
    $debugName: 'metroLayoutClassName',

    display: 'block',
    position: 'relative',
    $nest: clearCSS
});

export const metroGroupClassName = style({
    $debugName: 'metroGroupClassName',

    display: 'block',
    position: 'relative',
    $nest: clearCSS
});

export const metroBlockClassName = style(
    {
        $debugName: 'metroBlockClassName',

        display: 'block',
        position: 'relative',
        width: '100%',
        height: 'auto',
        padding: 0,
        margin: 0
    },
    media(MEDIA_SM, {
        float: 'left'
    })
);

export const metroBlockAreaClassName = style(
    {
        $debugName: 'metroBlockAreaClassName',
        display: 'block',
        position: 'relative',
        width: '100%',
        height: 0,
        padding: '0 0 100% 0',
        margin: 0
    },
    media(MEDIA_SM, {
        float: 'left'
    })
);

export const metroBlockInnerClassName = style({
    $debugName: 'metroBlockInnerClassName',

    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflow: 'visible'
});

export const sizeWidths = [
    100, 100, 50, 50, 25
];

export const metroBlockSize4ClassName = style(
    {
        width: '100%',
        $unique: true
    },
    media(MEDIA_SM, {
        width: '50%',
    }),
    media(MEDIA_MD, {
        width: '25%'
    })
);

export const metroBlockSize3ClassName = style(
    {
        width: '100%',
        $unique: true,

        $nest: {
            [`.${metroBlockAreaClassName}`]: {
                paddingBottom: '50%'
            }
        }
    },
    media(MEDIA_MD, {
        width: '50%'
    })
);

export const metroBlockSize2ClassName = style(
    {
        width: '100%',
        $unique: true,

        $nest: {
            [`.${metroBlockSize4ClassName}`]: {
                width: '100%'
            },
            [`.${metroBlockSize3ClassName}`]: {
                width: '100%'
            }
        }
    },
    media(MEDIA_SM, {
        $nest: {
            [`.${metroBlockSize4ClassName}`]: {
                width: '50%'
            },
            [`.${metroBlockSize3ClassName}`]: {
                width: '100%'
            }
        }
    }),
    media(MEDIA_MD, {
        width: '50%',

        $nest: {
            [`.${metroBlockSize4ClassName}`]: {
                width: '50%'
            },
            [`.${metroBlockSize3ClassName}`]: {
                width: '100%'
            }
        }
    })
);

export const metroBlockSize1ClassName = style({
    width: '100%',
    $unique: true,

    $nest: {
        [`.${metroBlockAreaClassName}`]: {
            paddingBottom: '50%'
        }
    }
});

export const metroBlockSize0ClassName = style({
    width: '100%',
    $unique: true,

    $nest: {
        [`.${metroBlockAreaClassName}`]: {
            paddingBottom: '0',
            height: 'auto',

            $nest: {
                [`.${metroBlockInnerClassName}`]: {
                    position: 'relative',
                    height: 'auto'
                }
            }
        }
    }
});




export const childBlockClassName = style({

    $nest: {
        [`&.${metroBlockSize0ClassName}`]: {
            width: '200%'
        }
    }
});

export const metroBlockGroupClassName = style(
    {
        $debugName: 'metroBlockGroupClassName',
        clear:'both',
    },
    media(MEDIA_XS_ONLY, {
        $nest: {
            [`& > .${metroBlockAreaClassName}`]: {
                height: 'auto',
                padding: 0,

                $nest: {
                    [`& > .${metroBlockInnerClassName}`]: {
                        height: 'auto',
                        position: 'relative'
                    }
                }
            }
        }
    }),
    media(MEDIA_SM, {
        float:'right',
    })
);

export const metroBlockGroupClassNameLeft = style(
    {
        $debugName: 'metroBlockGroupClassNameLeft',
        clear:'both',
    },
    media(MEDIA_XS_ONLY, {
        $nest: {
            [`& > .${metroBlockAreaClassName}`]: {
                height: 'auto',
                padding: 0,

                $nest: {
                    [`& > .${metroBlockInnerClassName}`]: {
                        height: 'auto',
                        position: 'relative'
                    }
                }
            }
        }
    })
);
export const exhibitionBoxFadeInFromBottom = style({
    $debugName: 'exhibitionsFadeInFromBottom',
    opacity: 0,
    transform: 'translateY(80px)',
    visibility: 'hidden',
    transition: 'opacity 800ms ease-out, transform 800ms ease-out,visibility 800ms ease-out',
    willChange: 'opacity, transform, visibility',
 })
 
 export const exhibitionBoxIsVisible = style({
    $debugName: 'exhibitionsVisible',
    opacity: 1,
    transform: 'none',
    visibility: 'visible',
    
 })